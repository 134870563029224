import React from "react";

export default function SmDocIcon({ color }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.32"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66683 3.83366C5.74636 3.83366 5.00016 4.57985 5.00016 5.50033V15.5003C5.00016 16.4208 5.74635 17.167 6.66683 17.167H13.3335C14.254 17.167 15.0002 16.4208 15.0002 15.5003V8.83366H16.6668V15.5003C16.6668 17.3413 15.1744 18.8337 13.3335 18.8337H6.66683C4.82588 18.8337 3.3335 17.3413 3.3335 15.5003V5.50033C3.3335 3.65938 4.82588 2.16699 6.66683 2.16699H10.0002V3.83366H6.66683Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6665 5.50033V3.91701C13.2991 4.24841 14.5851 5.53438 14.9165 7.16699H13.3332C12.4127 7.16699 11.6665 6.4208 11.6665 5.50033ZM16.6665 8.00033V8.83366H13.3332C11.4922 8.83366 9.99984 7.34127 9.99984 5.50033V2.16699H10.8332C14.0548 2.16699 16.6665 4.77866 16.6665 8.00033ZM6.6665 8.00033C6.6665 7.54033 7.03984 7.16699 7.49984 7.16699H8.33317C8.79317 7.16699 9.1665 7.54033 9.1665 8.00033C9.1665 8.46033 8.79317 8.83366 8.33317 8.83366H7.49984C7.03984 8.83366 6.6665 8.46033 6.6665 8.00033ZM6.6665 11.3337C6.6665 10.8737 7.03984 10.5003 7.49984 10.5003H12.4998C12.9598 10.5003 13.3332 10.8737 13.3332 11.3337C13.3332 11.7937 12.9598 12.167 12.4998 12.167H7.49984C7.03984 12.167 6.6665 11.7937 6.6665 11.3337ZM6.6665 14.667C6.6665 14.207 7.03984 13.8337 7.49984 13.8337H12.4998C12.9598 13.8337 13.3332 14.207 13.3332 14.667C13.3332 15.127 12.9598 15.5004 12.4998 15.5004H7.49984C7.03984 15.5004 6.6665 15.127 6.6665 14.667Z"
        fill={color}
      />
    </svg>
  );
}
