/* eslint-disable react-hooks/rules-of-hooks */
import classNames from "classnames";
import React, { useState } from "react";
import VideoIcon from "../../../assets/img/icons/common/icon-video.svg";
import PlusIcon from "../../../assets/img/icons/common/icon-plus.svg";
import TextIcon from "../../../assets/img/icons/common/icon-text.svg";
import DocIcon from "../../../assets/img/icons/common/icon-doc.svg";
import CheckIcon from "../../../assets/img/icons/common/icon-checkmark.svg";
import { useParams } from "react-router";
import api2 from "api2";
import axios from "axios";
// import { Draggable, Droppable } from 'react-drag-and-drop'
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { MdOutlineDragIndicator } from "react-icons/md";

export default function ContentPanel({
  item,
  addCourse,
  setAddCourse,
  index,
  handleSelectSection,
  setIsLectureValid,
  setIsSectionValid,
  selectedContent,
  setSelectedIndex
}) {
  const { id } = useParams();
  const [panel, setPanel] = useState(index === 0);
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [lecture, setLecture] = useState();
  useEffect(() => {
    let array = [];
    item?.contents.map((item, index) => {
      array.push({
        ...item,
        id: index,
      });
    });
    setLecture({ contents: array });
  }, [item, addCourse]);
  const handleAddLecture = async (index) => {
    if (!selectedContent?.section.title) {
      setIsSectionValid(false);
    } else {
      setIsSectionValid(true);
    }
    if (!selectedContent?.lecture.title) {
      setIsLectureValid(false);
    } else {
      setIsLectureValid(true);
    }
    if (!selectedContent?.section.title || !selectedContent?.lecture.title) {
      return;
    }
    if (
      !addCourse.courseContents[index].contents[
        addCourse.courseContents[index].contents.length - 1
      ]?.title
    ) {
      setIsLectureValid(false);
    } else {
      setIsLectureValid(true);
    }
    if (
      !item?.title ||
      !addCourse.courseContents[index].contents[
        addCourse.courseContents[index].contents.length - 1
      ]?.title
    ) {
      return;
    }
    if (id) {
      let payload = {
        title: "",
        type: "video",
        link: "",
        duration: 0,
      };
      try {
        const config = {
          headers: { Authorization: token },
        };
        let result = await api2.post(`api/courseContent`, payload, config);
        let obj = {
          ...result.data.data,
          id: addCourse.courseContents[index].contents.length - 1,
        };
        addCourse.courseContents[index].contents.push(obj);
        setLecture([...addCourse.courseContents[index]]);
        return result.data.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error("Cancelled");
        }
      }
    } else {
      addCourse.courseContents[index].contents.push({
        title: "",
        type: "video",
        link: "",
        duration: 0,
        _id: addCourse.courseContents[index].contents.length + 1,
      });
    }
    handleSelectSection(
      item,
      addCourse.courseContents[index].contents[
        addCourse.courseContents[index].contents.length - 1
      ]
    );
    setAddCourse({
      ...addCourse,
    });
  };
  const handleRLDDChange = (reorderedItems) => {
    setLecture({ contents: reorderedItems });
    addCourse.courseContents[index].contents = reorderedItems;
    setAddCourse({
      ...addCourse,
    });
  };
  const itemRenderer = (v, i) => {
    return (
      <div
        className="lecture-section-wrapper"
        onClick={() => {
          handleSelectSection(item, v)
          setSelectedIndex(index)
        }}
      >
        {v.type === "video" && (
          <div className="d-flex align-items-center">
            <MdOutlineDragIndicator />
            <img src={VideoIcon} alt="" />
          </div>
        )}
        {v.type === "text" && (
          <div className="d-flex align-items-center">
            <MdOutlineDragIndicator />
            <img src={TextIcon} alt="" />
          </div>
        )}
        {v.type === "doc" && (
          <div className="d-flex align-items-center">
            <MdOutlineDragIndicator />
            <img src={DocIcon} alt="" />
          </div>
        )}
        {v.type === "mcq" && (
          <div className="d-flex align-items-center">
            <MdOutlineDragIndicator />
            <img src={CheckIcon} alt="" />
          </div>
        )}
        {v.type === "lab" && (
          <div className="d-flex align-items-center">
            <MdOutlineDragIndicator />
            <img src={CheckIcon} alt="" />
          </div>
        )}

        <div className="d-flex flex-column">
          <span className="lecture-section-title">
            {!v.title && "Lecture -"}{" "}
            <span className="text-trans">
              {i + 1}. {v.title}
            </span>
          </span>
          <span className="lecture-section-subtitle">{v.duration} h</span>
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        className="accordion-button course-section-head"
        type="button"
        onClick={() => setPanel(!panel)}
      >
        <i className="fas fa-chevron-right mr-2"></i>
        <div className="d-flex flex-column">
          <span className="course-section-name">
            {" "}
            {!addCourse?.courseContents[index].title && "Section"} {index + 1}.{" "}
            {addCourse?.courseContents[index].title}{" "}
          </span>
          <span className="course-section-lecture">
            {lecture?.contents.length} Lectures
          </span>
        </div>
      </div>
      <div
        id="collapseOne"
        className={classNames("accordion-collapse collapse", {
          show: panel,
        })}
      >
        <div className="accordion-body d-flex flex-column">
          {lecture?.contents.length && (
            <RLDD
              cssClasses="list-container"
              items={lecture?.contents}
              itemRenderer={itemRenderer}
              onChange={handleRLDDChange}
            />
          )}
          <button
            className="add-lecture-section-wrapper"
            type="button"
            onClick={() => handleAddLecture(index)}
          >
            <img src={PlusIcon} alt="" />
            Add Lecture
          </button>
        </div>
      </div>
    </>
  );
}
