import { useRef } from 'react';
import { StyledDropZone } from 'react-drop-zone';
import 'react-drop-zone/dist/styles.css';
import DocIcon from '../../assets/img/icons/common/icon-doc.svg';
import IconFile from '../../assets/img/icons/common/icon-img.svg';
import VideoIcon from '../../assets/img/icons/common/icon-video.svg';

const NewCustomDropzon = ({ name, handleUpload, type, imageType }) => {
  const ref = useRef();
  const handleClick = () => {
    ref.current.click();
  };
  return (
    <>
      <div className="add_course_form_file_input">
        {type === 'img' && (
          <img src={IconFile} alt="" className="add_course_form_file_icon" />
        )}
        {type === 'video' && (
          <img src={VideoIcon} alt="" className="add_course_form_file_icon" />
        )}
        {type === 'document' && (
          <img src={DocIcon} alt="" className="add_course_form_file_icon" />
        )}

        <span className="add_course_form_file_text">
          Drag and drop an image, or import from computer
        </span>
        <StyledDropZone
          onDrop={(file) => handleUpload(file, name)}
          label={'Drag and drop an image, or import from computer'}
        />

        <button
          className="add_course_form_file_submit"
          type="button"
          onClick={handleClick}
        >
          Upload {imageType}
        </button>
      </div>
      <input
        ref={ref}
        type="file"
        accept="image/png,image/jpeg,video/mp4"
        className="d-none"
        onChange={(e) => {
          handleUpload(e.target.files[0], name);
        }}
      />
    </>
  );
};

export default NewCustomDropzon;
