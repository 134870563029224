import React, { useState } from "react";
import {
  Form,
  Input,
  Row,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import classNames from "classnames";

import DoubleRightIcon from "../../../assets/img/icons/common/icon-double-chevron-right.svg";
import DoubleLeftIcon from "../../../assets/img/icons/common/icon-double-chevron-left.svg";
import { useSelector } from 'react-redux';

const CoursePublish = ({ addCourse, setAddCourse, setPageView }) => {
  const auth = useSelector((state) => state.authState);

  const handleBack = () => {
    setPageView('courseContent');
  };
  const handleChange = (name, value) => {
    setAddCourse({
      ...addCourse,
      [name]: value,
    });
  };
  return (
    <>
      <Form role="form" className="add_course_form">
        <Row>
          <div className="col-md-8">
            {/*  Course Price */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Course Price
              </label>
              <Input
                placeholder="Enter course price"
                type="number"
                autoComplete="off"
                name="price"
                value={addCourse?.price}
                onChange={(e) =>
                  handleChange('price', parseInt(e.target.value || '0'))
                }
                className="add_course_form_input"
              />
            </FormGroup>
            {/*  Total Duration */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Total Duration
              </label>
              <Input
                placeholder="Enter total duration"
                type="text"
                autoComplete="off"
                className="add_course_form_input"
                value={addCourse?.duration}
                onChange={(e) =>
                  handleChange('duration', parseInt(e.target.value || 0))
                }
              />
            </FormGroup>
            {/*  Course Link */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Download Material
              </label>
              <Input
                placeholder="Enter total duration"
                type="text"
                autoComplete="off"
                className="add_course_form_input"
                value={addCourse?.downloadMaterial}
                onChange={(e) =>
                  handleChange('downloadMaterial', e.target.value)
                }
              />
            </FormGroup>
            {(auth?.user?._id === addCourse?.createdBy?._id ||
              !addCourse?.createdBy?._id) && (
              <FormGroup className="my-4 d-flex algin-items-center gap-4">
                <label className="add_course_form_label mx-0">
                  Public Course
                </label>

                <label className="is_private_toggle" for="myToggle">
                  <Input
                    className="toggle__input"
                    name=""
                    type="checkbox"
                    id="myToggle"
                    checked={addCourse?.isPublic}
                    onChange={(e) => handleChange('isPublic', e.target.checked)}
                  />
                  <div className="toggle__fill"></div>
                </label>
              </FormGroup>
            )}
            {/* Course Privacy */}
            {/* <FormGroup className="my-4 d-flex algin-items-center gap-4">
              <label className="add_course_form_label mx-0">
                Public Course
              </label>

              <label className="is_private_toggle" for="myToggle">
                <Input
                  className="toggle__input"
                  name=""
                  type="checkbox"
                  id="myToggle"
                  defaultChecked="true"
                  checked={addCourse?.isPublic}
                  onChange={(e) => handleChange("isPublic", e.target.checked)}
                />
                <div className="toggle__fill"></div>
              </label>
            </FormGroup> */}
          </div>
        </Row>
        <div className="d-flex justify-content-between mt-5">
          <button
            className="add_course_form_back_btn"
            type="button"
            onClick={handleBack}
          >
            <img src={DoubleLeftIcon} alt="" />
            Back
          </button>
          {/* <button className="add_course_form_continue_btn" type="button" onClick={()=>setPageView("publish")}>
          Continue
          <img src={DoubleRightIcon} alt="" />
        </button> */}
        </div>
      </Form>
    </>
  );
};

export default CoursePublish;
