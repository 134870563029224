import { Box, Button, MenuItem, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { deleteLab, getAllLab } from 'actions/adminActions/labsActions';
import classNames from 'classnames';
import MuiOverride from 'components/Common/MuiOverride';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import PlusIcon from 'iconComponents/PlusIcon';
import SweetAlert from 'react-bootstrap-sweetalert';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Card, CardHeader, Container } from 'reactstrap';
import { loaderType } from 'type';

const Labs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [labId, setLabId] = useState(null);
  const labs = useSelector((state) => state.labs.labs);

  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });

  useEffect(() => {
    trackPromise(dispatch(getAllLab(0, 3000)), loaderType.GENERAL_REQUEST);
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'title',
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              cursor: 'pointer',
              color: 'var(--primary)',
            }}
            onClick={() => history.push(`labs/edit/${row?.original?.id}`)}
          >
            <Typography className="">{row?.original?.title}</Typography>
          </Box>
        ),
      },
      {
        header: 'Category',
        accessorKey: 'categoryName',
        Cell: ({ row }) => (
          <Typography className="">{row?.original?.categoryName}</Typography>
        ),
      },
      {
        header: 'Difficulty',
        accessorKey: 'difficulty',
        Cell: ({ row }) => (
          <Badge
            className={classNames(
              'complexity-badge',
              {
                'complexity-bg-badge-success':
                  row?.original?.difficulty === 'easy',
              },
              {
                'complexity-bg-badge-warning':
                  row?.original?.difficulty === 'medium',
              },
              {
                'complexity-bg-badge-danger':
                  row?.original?.difficulty === 'hard',
              }
            )}
          >
            {row?.original?.difficulty}
          </Badge>
        ),
      },
      {
        header: 'Points',
        accessorKey: 'score',
        Cell: ({ row }) => (
          <Typography className="">{row?.original?.score}</Typography>
        ),
      },
      {
        header: 'Solution',
        Cell: ({ row }) => (
          <Link
            target="_blank"
            to={'https://vlabs.cyberange.io/m/lab/' + row?.original?._id}
            rel="noreferrer"
          >
            <Typography sx={{ color: 'var(--primary)' }}>
              https://vlabs.cyberange.io/m/lab/{row?.original?._id}
            </Typography>
          </Link>
        ),
      },
    ],
    [history]
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };

  const confirmAlert = () => {
    trackPromise(dispatch(deleteLab(labId)), loaderType.GENERAL_REQUEST);
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'success',
      message: 'Lab deleted successfully.',
    });
  };

  const handleExportData = () => {
    const preprocessData = (data) => {
      return data.map((item) => {
        const processedItem = {};
        Object.keys(item).forEach((key) => {
          const value = item[key];
          if (typeof value === 'object' && value !== null) {
            processedItem[key] = JSON.stringify(value);
          } else {
            processedItem[key] = value;
          }
        });
        return processedItem;
      });
    };

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Labs Data',
      useBom: true,
      useKeysAsHeaders: true,
    });

    const processedLabs = preprocessData(labs);
    const csv = generateCsv(csvConfig)(processedLabs);
    download(csvConfig)(csv);
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header">
            <div>
              <h5 className="org_page_title">Labs</h5>
            </div>
            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push('/addLabs')}
              >
                <PlusIcon color="white" />
                Add Lab
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={labs || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <MenuItem
                key={1}
                onClick={() => {
                  history.push(`/labs/edit/${row?.original?.id}`);
                }}
                sx={{ m: 0 }}
              >
                Edit
              </MenuItem>,
              <MenuItem
                key={2}
                onClick={() => {
                  handleDelete();
                  setLabId(row?.original?._id);
                }}
                sx={{ m: 0 }}
              >
                Delete
              </MenuItem>,
            ]}
            renderTopToolbarCustomActions={() => (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  padding: '8px',
                  flexWrap: 'wrap',
                }}
              >
                <button
                  className="pr-btn-with-icon"
                  color="primary"
                  onClick={handleExportData}
                  variant="contained"
                >
                  <FileDownloadIcon /> Export All Data
                </button>
              </Box>
            )}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'warning' && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={<span style={{ fontSize: '24px' }}>{alert?.message}</span>}
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === 'success' && alert.isOpenAlert && (
        <SweetAlert
          success
          title={<span style={{ fontSize: '24px' }}>{alert?.message}</span>}
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default Labs;
