import { getMyCourseExam } from "actions/adminActions/courseAction";
import { getAllUser } from "actions/adminActions/usersActions";
import api2 from "api2";
import PlusIcon from "iconComponents/PlusIcon";
import TrashIcon from "iconComponents/TrashIcon";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Select from "react-select";
import {
  Card,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import ConfirmationModel from "components/Common/ConfirmationModel";
import { Checkbox } from "@mui/material";
import InfoIcon from "iconComponents/InfoIcon";
import ScreenIcon from "components/Icons/ScreenIcon";
import CamIcon from "components/Icons/CamIcon";
import MicIcon from "components/Icons/MicIcon";
const userTypeList = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Organization",
    value: "organization",
  },
];
const Schedule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [scheduleData, setScheduleData] = useState({
    startTime: dayjs(scheduleData?.startTime).$d,
    expireTime: dayjs(scheduleData?.expireTime).add(1, "hour").$d,
    allowedScreen: true,
    allowedAudio: true,
    allowedVideo: true,
  });
  const [allExam, setAllExam] = useState();
  const [allUsers, setAllUsers] = useState();
  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });
  const users = useSelector((state) => state.users.userData);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 45,
    }),
  };
  const handleChange = (name, value) => {
    setScheduleData({
      allowScreen: scheduleData?.allowedVideo || scheduleData?.allowedAudio,
      ...scheduleData,
      [name]: value,
    });
  };
  const myExamList = useSelector((state) => state?.courses?.myExamList);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  useEffect(() => {
    dispatch(getMyCourseExam(0, 1000, 'exam'));
    dispatch(getAllUser(0, 1000));
  }, [dispatch]);
  useEffect(() => {
    if (myExamList?.length) {
      let examList = myExamList.map((i) => {
        return {
          label: i?.name,
          value: i?._id,
        };
      });
      setAllExam(examList);
    }
  }, [myExamList]);

  useEffect(() => {
    if (users.length) {
      let usersList = users.map((i) => {
        return {
          label: i?.name,
          value: i?._id,
        };
      });
      setAllUsers(usersList);
    } else {
      setAllUsers([]);
    }
  }, [users]);
  const handlePublish = async () => {
    // scheduleData["courseExamId"] = scheduleData?.courseExamId?.value;
    scheduleData['userIds'] = scheduleData?.userIds?.map((i) => {
      return {
        userId: i?.value,
        resultId: [],
      };
    });
    try {
      const config = {
        headers: { Authorization: token },
      };
      let payload = {
        ...scheduleData,
        courseExamId: scheduleData?.courseExamId?.value,
      };

      await api2.post(`/api/scheduledExams`, payload, config);

      setAlert({
        ...alert,
        isOpenAlert: true,
        status: 'success',
        message: 'Exam scheduled successfully.',
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card className="p-lg-4 ">
                <div className="add_course_page_header">
                  <div className="d-flex gap-3 align-items-center">
                    <h5 className="org_page_title">Schedule</h5>
                    {/* <span className="draft_badge">Draft</span> */}
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <button
                      className="cancel_form_btn"
                      onClick={() => history.push('/report')}
                    >
                      Cancel
                    </button>
                    {/* <button className="draft_form_btn">Save Draft</button> */}
                    <button
                      className="publish_form_btn"
                      onClick={handlePublish}
                    >
                      Publish
                    </button>
                  </div>
                </div>

                <Form role="form" className="add_exam_form">
                  <Row className="">
                    <div className="col-md-8 my-3 gap-2 form-content-wrapper-main">
                      <FormGroup className="">
                        <label className="org_form_label my-2 mx-0">
                          Schedule Name
                        </label>
                        <Input
                          placeholder="Your Schedule Name"
                          type="text"
                          autoComplete="off"
                          name="questionTitle"
                          className="org_form_input"
                          value={scheduleData?.name}
                          onChange={(e) => handleChange('name', e.target.value)}
                        />
                      </FormGroup>

                      <Row>
                        {/* <div className="col-md-6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Unique Link
                            </label>
                            <Input
                              placeholder="labs.cyberange.io/hsy278"
                              type="text"
                              autoComplete="off"
                              name="questionTitle"
                              className="org_form_input"
                              value={scheduleData?.link}
                              onChange={(e) => handleChange("link", e.target.value)}
                            />
                          </FormGroup>
                        </div> */}
                        <div className="col-md-12">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Set Duration{' '}
                              <span className="sub-title">(minutes)</span>
                            </label>

                            <Input
                              placeholder="30 mins"
                              type="number"
                              autoComplete="off"
                              name="questionTitle"
                              className="org_form_input"
                              // value={
                              //   scheduleData &&
                              //   `${scheduleData?.duration.split(".")[0]}:${
                              //     scheduleData?.duration.split(".")[1]
                              //   }`
                              // }
                              onChange={(e) => {
                                // const time = e.target.value;
                                // let minutes =
                                //   `${parseInt(time.split(":")[0])}.${parseInt(
                                //     time.split(":")[1]
                                //   )}` * 60;
                                handleChange('duration', e.target.value);
                              }}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <label className="org_form_label my-2 mx-0">
                            {' '}
                            Start Date{' '}
                          </label>

                          <LocalizationProvider
                            LocalizationProvider
                            dateAdapter={AdapterDayjs}
                          >
                            <DemoContainer components={['DateTimePicker']}>
                              <DateTimePicker
                                inputFormat="MM/DD/YYYY hh:mm a"
                                minDate={dayjs()}
                                defaultValue={dayjs(scheduleData?.startTime)}
                                onChange={(selectedDate) => {
                                  handleChange('startTime', selectedDate?.$d);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="col-md-6">
                          <label className="org_form_label my-2 mx-0">
                            {' '}
                            Expiry Date{' '}
                          </label>
                          <LocalizationProvider
                            LocalizationProvider
                            dateAdapter={AdapterDayjs}
                          >
                            <DemoContainer components={['DateTimePicker']}>
                              <DateTimePicker
                                inputFormat="MM/DD/YYYY hh:mm a"
                                minDate={dayjs()}
                                defaultValue={dayjs(scheduleData?.expireTime)}
                                onChange={(selectedDate) => {
                                  handleChange('expireTime', selectedDate?.$d);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </Row>

                      <FormGroup className="w-100">
                        <label className="org_form_label my-2 mx-0">
                          Description
                        </label>
                        <ReactQuill
                          className="react_quill"
                          theme="snow"
                          placeholder="Add description.."
                          bounds={'.app'}
                          value={scheduleData?.description}
                          onChange={(value) => {
                            handleChange('description', value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="w-100">
                        <label className="org_form_label my-2 mx-0">
                          Instruction
                        </label>
                        <ReactQuill
                          className="react_quill"
                          theme="snow"
                          placeholder="Add Instruction.."
                          bounds={'.app'}
                          value={scheduleData?.instruction}
                          onChange={(value) => {
                            handleChange('instruction', value);
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="">
                        <label className="org_form_label my-2 mx-0">
                          Select Exam
                        </label>
                        <Select
                          //   defaultValue={[colourOptions[2], colourOptions[3]]}

                          name="selectExam"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          styles={customStyles}
                          placeholder="Select"
                          options={allExam}
                          className="basic-multi-select w-100"
                          classNamePrefix="select"
                          value={scheduleData?.courseExamId}
                          onChange={(value) => {
                            handleChange('courseExamId', value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="">
                        <label className="org_form_label my-2 mx-0">
                          Select Users
                        </label>
                        <Select
                          //   defaultValue={[colourOptions[2], colourOptions[3]]}
                          isMulti
                          name="selectUsers"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={allUsers}
                          className="basic-multi-select w-100"
                          classNamePrefix="select"
                          value={scheduleData?.userIds}
                          onChange={(value) => {
                            handleChange('userIds', value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="m-0 d-flex align-items-center gap-3">
                        <CamIcon color="var(--primary)" />
                        Record Video
                        <Checkbox
                          className="py-2"
                          checked={scheduleData?.allowedVideo}
                          onChange={(e) =>
                            handleChange('allowedVideo', e.target.checked)
                          }
                        />
                      </FormGroup>
                      <FormGroup className="m-0 d-flex align-items-center gap-3">
                        <MicIcon color="var(--primary)" />
                        Record Audio
                        <Checkbox
                          className="py-2"
                          checked={scheduleData?.allowedAudio}
                          onChange={(e) =>
                            handleChange('allowedAudio', e.target.checked)
                          }
                        />
                      </FormGroup>
                      <FormGroup className="m-0 d-flex align-items-center gap-3">
                        <ScreenIcon color="var(--primary)" />
                        Record Screen
                        <Checkbox
                          className="py-2"
                          readOnly
                          checked={
                            scheduleData?.allowedVideo ||
                            scheduleData?.allowedAudio
                          }
                        />
                      </FormGroup>
                      {/* {!scheduleData?.allowedAudio && !scheduleData?.allowedVideo && "In order to record screen, please enable video or audio."} */}

                      {!scheduleData?.allowedAudio &&
                        !scheduleData?.allowedVideo && (
                          <>
                            <div className="d-flex gap-2 my-2 course-thumbnail-info">
                              <InfoIcon color={'#070707'} />
                              <span className="add_course_form_sublabel">
                                In order to record screen, please enable video
                                or audio.
                              </span>
                            </div>
                          </>
                        )}

                      <FormGroup className="my-4 d-flex algin-items-center gap-4">
                        <label className="add_course_form_label mx-0">
                          Show Result to the Student?
                        </label>

                        <label className="is_private_toggle" for="myToggle">
                          <Input
                            className="toggle__input"
                            name=""
                            type="checkbox"
                            id="myToggle"
                            checked={scheduleData?.isPublic}
                            onChange={(e) =>
                              handleChange('isPublic', e.target.checked)
                            }
                          />
                          <div className="toggle__fill"></div>
                        </label>
                      </FormGroup>
                    </div>
                  </Row>
                </Form>
              </Card>
            </div>
          </div>
        </Row>
        {alert.status === 'success' && alert.isOpenAlert && (
          <ConfirmationModel
            status={alert.status}
            isOpen={alert.isOpenAlert}
            buttonText="OK"
            confirmation={() => {
              setAlert({ isOpenAlert: false });
              history.push('/report');
            }}
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
          />
        )}
      </Container>
    </>
  );
};

export default Schedule;
