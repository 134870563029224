import { combineReducers } from "redux";
import authReducer from "reducers/authReducer";
import categoriesReducer from "reducers/categoriesReducer";
import labsReducer from "reducers/labsReducer";
import plansReducer from "reducers/plansReducer";
import usersReducer from "reducers/usersReducer";
import organisationReducer from "reducers/organisationsReducer";
import quizReducer from "reducers/quizReducers";
import templateReducer from "reducers/templateReducer";
import customCategoriesReducer from "reducers/customCategoriesReducer";
import customChallengesReducer from "reducers/customLabsReducer";
import courseReducer from "./courseReducer";
import examReducer from "./examReducer";
import CourseExamsReducer from "./courseExamReducer";
import usersProfileReducer from "./usersProfileReducer";

export default combineReducers({
  authState: authReducer,
  categories: categoriesReducer,
  users: usersReducer,
  plans: plansReducer,
  organisation: organisationReducer,
  quiz:quizReducer,
  labs: labsReducer,
  examlabs: examReducer,
  examsData: CourseExamsReducer,
  template: templateReducer,
  customCategory: customCategoriesReducer,
  customChallenge: customChallengesReducer,
  usersProfile: usersProfileReducer,

  courses: courseReducer,
});
