import API from "api2";
import { showAlert } from "actions/templateAction";
import api2 from 'api2';

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const createLab = (formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: 'post',
        url: `/api/admin/template/parse`,
        headers: config(token),
        data: [formData],
      })
        .then(async (res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch({ type: 'CREATE_CHALLENGE_SUCCESS' });
            dispatch(showAlert(true, 'success', data.message));
            resolve();

            // const config = {
            //   headers: { Authorization: token },
            // };
            //  await API({
            //    method: 'post',
            //    url: `api/challenge`,
            //    headers: config(token),
            //    data: [formData],
            //  })
            //    .then((res) => {
            //    })
            //    .catch((err) => {
            //      reject();
            //    });
          }
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'CREATE_CHALLENGE_ERROR' }, err);
          dispatch(showAlert(true, 'failed', errMessage));
          reject();
        });
    });
  };
};

export const getLab = (id) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: "get",
        url: `api/challenge/${id}`,
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "GET_SINGLE_CHALLENGE_SUCCESS",
            data: res.data.data,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({ type: "GET_SINGLE_CHALLENGE_ERROR" }, err);
          dispatch(showAlert(true, "failed", errMessage));
          reject();
        });
    });
  };
};

export const editLab = (challengesId, formData) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: "put",
        url: `api/challenge/${challengesId}`,
        headers: config(token),
        data: {
          title: formData.title,
          content: formData.content,
          instructions: formData.instructions,
          flagQuestion: formData.flagQuestion,
          flag: formData.flag,
          categoryId: formData.categoryIds.length ? formData.categoryIds[0] : "",
          categoryIds: formData.categoryIds,
          solution: formData.solution,
          solutionContent: formData?.solutionContent,
          difficulty: formData.difficulty,
          score: formData.score,
          videoURL: formData.videoURL,
          thumbnailImageUrl: formData.thumbnailImageUrl,
          duration: formData.duration
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: "UPDATE_CHALLENGE_SUCCESS" });

          dispatch(showAlert(true, "success", data.message));
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({ type: "UPDATE_CHALLENGE_ERROR", err });
          dispatch(showAlert(true, "warning", errMessage));
          reject({ success: false, message: errMessage });
        });
    });
  };
};

export const deleteLab = (challengesId) => {
  return (dispatch, getState) => {
    const state = getState().labs.labs;
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: "delete",
        url: `api/challenge/${challengesId}`,
        headers: config(token),
      })
        .then(() => {
          let c = state.filter((item) => item._id !== challengesId);
          dispatch({ type: "DELETE_CHALLENGE_SUCCESS", data: c });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: "DELETE_CHALLENGE_ERROR" }, err);
          reject();
        });
    });
  };
};

export const getAllLab = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: "get",
        url: `api/challenge?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: "GET_ALL_LAB_SUCCESS",
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({
            type: "GET_ALL_LAB_FAILED",
            payload: "failed",
            err: errMessage,
          });
          reject();
        });
    });
  };
};
