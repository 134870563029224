import API from "api2";
import firebaseConfig from "firebaseConfig";
import { getFirestore } from "redux-firestore";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const createOrganisation = (state) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `auth/register`,
        headers: config(token),
        data: {
          name: state.name,
          email: state.email,
          password: state.password,
          sendEmail: state.sendEmail,
          phoneNumber: state.phoneNumber,
          userType: 'organisation',
          // eslint-disable-next-line eqeqeq
          isActive: state.isActive == 1 ? true : false,
          tabAccess: state.tabAccess,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'CREATE_ORGANISATION_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errData =
            (err.response && err.response.data) || 'Unknown Error Occured';
          dispatch({ type: 'CREATE_ORGANISATION_ERROR' }, err);
          reject({ success: false, message: errData.message });
        });
    });
  };
};

export const editOrganisation = (orgId, state) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: "put",
        url: `api/user/organisation/${orgId}/details`,
        headers: config(token),
        data: {
          name: state.name,
          email: state.email,
          tabAccess:state.tabAccess
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: "UPDATE_ORGANISATION_SUCCESS" });
          resolve({ success: true, data });
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({ type: "UPDATE_ORGANISATION_ERROR" }, err);
          reject({ success: false, message: errMessage });
        });
    });
  };
};

// export const deleteOrganisation = (orgId) => {
//   return (dispatch, getState) => {
//     const firestore = getFirestore();
//     firestore
//       .collection(`vcr/db/organization`)
//       .doc(orgId)
//       .delete()
//       .then(() => {
//         dispatch({ type: "DELETE_ORGANISATION_SUCCESS" });
//       })
//       .catch((err) => {
//         dispatch({ type: "DELETE_ORGANISATION_ERROR" }, err);
//       });
//   };
// };

export const deleteOrganisation = (orgId, actionType) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'delete',
        url: `api/user/${orgId}?actionType=${actionType}`,
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          // let c = state.filter((item) => item.id !== orgId);
          dispatch({ type: 'DELETE_ORGANISATION_SUCCESS' });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: 'DELETE_ORGANISATION_ERROR' }, err);
          reject();
        });
    });
  };
};

export const getAllOrgUser = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: "get",
        url: `api/user?userType=organisation&skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          const userlist = res.data.data.list;
          const newlist = userlist.map((x) => {
            if (x.licenseIds) {
              const slicence = x.licenseIds.map((licence) => {
                return licence.name;
              });
              x["licenseIds"] = slicence.toString();
              return x;
            } else {
              x["licenseIds"] = "";
              return x;
            }
          });
          dispatch({
            type: "FETCH_ALL_ORG_SUCCESS",
            payload: true,
            // data: res.data.data.list,
            data: newlist,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({
            type: "FETCH_ALL_ORG_FAILED",
            payload: "failed",
            err: errMessage,
          });
          reject();
        });
    });
  };
};

export const getOrgUsers = (id, skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken || null;
    if (id === 0) {
      dispatch({
        type: "FETCH_ORGUSERS_SUCCESS",
        payload: true,
        data: [],
      });
    } else {
      return new Promise((resolve, reject) => {
        API({
          method: "get",
          url: `api/user/organisation/${id}?skip=${skip}&limit=${limit}`,
          headers: config(token),
        })
          .then((res) => {
            dispatch({
              type: "FETCH_ORGUSERS_SUCCESS",
              payload: true,
              data: res.data.data.list,
            });
            resolve();
          })
          .catch((err) => {
            let errMessage =
              (err.response && err.response.data.message) ||
              "Unknown Error Occured";
            dispatch({
              type: "FETCH_ORGUSERS_FAILED",
              payload: "failed",
              err: errMessage,
            });
            reject();
          });
      });
    }
  };
};
