import classNames from "classnames";
import CloseIcon from "iconComponents/CloseIcon";
import InfoIcon from "iconComponents/InfoIcon";
import PlusIcon from "iconComponents/PlusIcon";
import React from "react";
import { useState } from "react";
import Select from "react-select";
import Select2 from "select2";
import API from "api2";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
} from "reactstrap";
import "./Quiz.css";
import { Autocomplete, Drawer } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllQuestionTags } from "actions/adminActions/quizActions";
import ConfirmationModel from "components/Common/ConfirmationModel";
import { TextField, makeStyles } from "@material-ui/core";
import { getAllQuestionCategory } from "actions/adminActions/courseAction";
import IconFile from "../../../assets/img/icons/common/icon-img.svg";
import api2 from "api2";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};
const tagsListArray = [
  {
    label: 'Attack',
    value: 'attack',
  },
  {
    label: 'Cyber',
    value: 'cyber',
  },
  {
    label: 'Threat',
    value: 'threat',
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: '1 !important',
  },
  drawerPaper: {
    maxWidth: 550,
  },
}));

const AddQuestion = ({
  isOpen,
  editQuestion,
  setEditQuestion,
  handleModel,
}) => {
  const dispatch = useDispatch();
  const [complexityList, setComplexityList] = useState([
    {
      label: 'Easy',
      value: 'easy',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
    {
      label: 'Hard',
      value: 'hard',
    },
  ]);
  const [categorylist, setCategorylist] = useState([]);
  const [isValidateFiled, setIsValidateFiled] = useState();
  const [isNullQuestion, setIsNullQuestion] = useState();
  const [invalid, setInValid] = useState(false);
  const [questionType, setQuestionType] = useState('single');
  // const [questionAnswerType, setQuestionAnswerType] = useState("text");
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([
    {
      type: 'text',
      value: '',
    },
  ]);
  const orgId = useSelector((state) => state?.authState?.user._id);
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [answer, setAnswer] = useState([]);
  const [complexity, setComplexity] = useState();
  const [categoriesList, setCategoriesList] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [questionCategories, setQuestionCategories] = useState();
  const [sameOptionsErr, setSameOptionsErr] = useState();
  const [tagsList, setTagsList] = useState(tagsListArray);
  const [inputValue, setInputValue] = useState();
  const [showImage, setShowImage] = useState(true);
  const [isDisabled, setIsDisabled] = useState();
  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });
  const questionCategory = useSelector(
    (state) => state?.categories.questionCategories
  );
  const questionTags = useSelector(
    (state) => state?.questionReducers?.questionTags
  );
  useEffect(() => {
    if (questionTags && Object.keys(questionTags).length > 0) {
      const questionTagsArray =
        questionTags?.length &&
        questionTags?.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      setTagsList(questionTagsArray);
    }
  }, [questionTags]);
  useEffect(() => {
    let questionCate = [questionCategories];
    questionCate =
      questionCategory?.length &&
      questionCategory.map((question) => {
        return {
          label: question?.name,
          value: question?._id,
        };
      });
    setQuestionCategories(questionCate);
  }, [questionCategory]);
  useEffect(() => {
    dispatch(getAllQuestionCategory(0, 1000));
    dispatch(getAllQuestionTags(0, 1000));
  }, []);
  useEffect(() => {
    if (!isOpen) {
      setQuestion('');
      setOptions(['']);
      setAnswer([]);
      setComplexity('');
      setSelectedTags([]);
      setSelectedCategory('');
    }
  }, [isOpen]);
  useEffect(() => {
    if (editQuestion) {
      setQuestion(editQuestion?.question);
      setQuestionType(
        editQuestion?.answer?.answers?.length === 1 ? 'single' : 'multiple'
      );
      // setQuestionAnswerType(editQuestion?.answer?.answers[0].includes(".png") || editQuestion?.answer?.answers[0].includes(".jpg") ? "image" : "text")
      let formatOptions = [];
      if (editQuestion?.options?.length) {
        formatOptions = editQuestion?.options.map((item) => {
          if ((item && item?.includes('.png')) || item?.includes('.jpg')) {
            return {
              type: 'image',
              value: item,
            };
          } else {
            return {
              type: 'text',
              value: item,
            };
          }
        });
      }
      setOptions(formatOptions);
      setAnswer(editQuestion?.answer?.answers);
      setComplexity(editQuestion?.difficulty);
      if (editQuestion?.tags?.length) {
        const selectedArray = [];
        editQuestion?.tags.forEach((i) => {
          let findValue =
            tagsList?.length && tagsList.find((v) => i === v.value);
          if (findValue) {
            selectedArray.push(findValue);
          }
        });
        let selectedValue =
          questionCategories?.length &&
          questionCategories?.find(
            (i) => i.value === editQuestion?.questionCategoryId?.id
          );
        setSelectedCategory(selectedValue);
        setSelectedTags(selectedArray);
      }
    }
  }, [editQuestion]);
  const handleQuestionType = (e) => {
    setQuestionType(e.target.id);
    setAnswer([]);
  };
  // const handleQuestionAnswer = (type) => {
  //   setQuestionAnswerType(type);
  //   setAnswer([]);
  // };
  const handleQuestion = (e) => {
    validate();
    setQuestion(e.target.value);
  };
  const handleOption = (e) => {
    const { value, id } = e.target;
    const optionsArray = [...options];
    if (value && optionsArray.includes(value.toLowerCase().trim())) {
      setSameOptionsErr(value);
    } else {
      setSameOptionsErr('');
    }
    optionsArray.splice(id, 1, { type: 'text', value });
    setInValid(false);

    setOptions(optionsArray);
  };
  const handleOptionFiles = async (e) => {
    const { files, id } = e.target;
    const optionsArray = [...options];
    if (
      files[0]?.name.split('.')[1] === 'png' ||
      files[0]?.name.split('.')[1] === 'jpg'
    ) {
      try {
        const config = {
          headers: { Authorization: token },
        };
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append('orgId', orgId);
        let result = await api2.post(`api/mcqs/upload`, formData, config);
        // setImageUrl({
        //   ...imageUrl,
        //   [name]: result?.data?.data?.fileName,
        // });
        optionsArray.splice(id, 1, {
          type: 'image',
          value: result?.data?.data?.fileName,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error('Cancelled');
        }
      }
    }
    // optionsArray.splice(id, 1, files[0]);
    setOptions(optionsArray);
  };
  const handleAddAnswer = (type) => {
    setIsNullQuestion(true);
    // if (!options[options?.length - 1]) {
    //   setInValid(true);
    //   return;
    // }
    const optionsArray = [...options];
    optionsArray.push({
      type: type,
      value: '',
    });
    setOptions(optionsArray);
  };
  const handleAnswer = (e) => {
    let answerArray = [...answer];
    let id = e.target.id;
    if (questionType === 'single') {
      answerArray = [options[id].value];
    } else {
      if (!e.target.checked) {
        const answerIndex = answerArray.indexOf(options[id]);
        answerArray.splice(answerIndex, 1);
      } else {
        answerArray.push(options[id].value);
      }
    }
    setAnswer(answerArray);
  };
  const handleComplexityChange = (selectedValue) => {
    setComplexity(selectedValue?.value);
    setIsValidateFiled({ ...isValidateFiled, hasComplexity: false });
  };
  const handleSelectTages = (e, newValue) => {
    if (e.type === 'click') {
      setSelectedTags(newValue);
    }
  };
  const handleSelectCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };
  const validate = () => {
    let questionName = false;
    let hasComplexity = false;
    if (!question) {
      questionName = true;
    }
    if (!complexity) {
      hasComplexity = true;
    }

    const obj = {
      ...isValidateFiled,
      questionName,
      hasComplexity,
    };
    setIsValidateFiled(obj);
  };
  const handleAddQuestion = async () => {
    setIsDisabled(true);
    validate();
    if (sameOptionsErr) {
      setAlert({
        isOpenAlert: true,
        status: 'warning',
        message: 'Please Enter unique options',
      });
      return;
    }

    if (question) {
      setIsDisabled(false);
      const tags = selectedTags.map((i) => {
        return i.value;
      });
      const category = selectedCategory?.value;
      if (!answer?.length) {
        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'warning',
          message: 'Please Provide Answers.',
        });
      }
      const payload = {
        question,
        options: options.map((item) => item?.value),
        tags,
        questionCategoryId: category,
        answers: answer,
        difficulty: complexity,
      };

      try {
        const config = {
          headers: { Authorization: token },
        };
        if (!editQuestion) {
          await API.post('/api/mcq', payload, config);
        } else {
          await API.put(`/api/mcq/${editQuestion?._id}`, payload, config);
        }
        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'success',
          message: editQuestion
            ? 'Question updated successfully!'
            : 'Question added successfully!',
        });
        const questionTags = tagsList.map((i) => i.value);
        await API.post(
          '/api/manageQuestionTags',
          { tags: questionTags },
          config
        );
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error('Cancelled');
        }
      }
    }
  };
  const handleRemove = (index) => {
    options.splice(index, 1);
    setOptions([...options]);
  };

  const classes = useStyles();

  return (
    <>
      <Drawer
        open={isOpen}
        anchor="right"
        // PaperProps={{
        //   sx: {
        //     maxWidth: 500,
        //   },
        // }}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
      >
        <div className="modal-body p-0 ">
          <Card className="border-0 mb-0 w-100">
            <CardHeader className="">
              <div className="d-flex justify-content-between align-items-center">
                <span className="modal-head-title">
                  {editQuestion ? 'Edit' : 'Add'} Question
                </span>

                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsValidateFiled({
                      questionName: false,
                      hasComplexity: false,
                    });
                    handleModel();
                    // setQuestionAnswerType("")
                  }}
                >
                  <CloseIcon color={'#8F8F92'} />
                </div>
              </div>
            </CardHeader>

            <CardBody className="quiz-body">
              <Form role="form">
                <div className="quiz-form-wrapper">
                  <FormGroup className="mb-4">
                    <label className="org_form_label my-2 mx-0">
                      Question Title
                    </label>
                    <Input
                      placeholder="Your Question Title"
                      type="text"
                      autoComplete="off"
                      name="questionTitle"
                      className="org_form_input"
                      value={question}
                      valid={isValidateFiled?.questionName && question}
                      invalid={isValidateFiled?.questionName && !question}
                      onChange={handleQuestion}
                    />
                  </FormGroup>

                  <div className="col-md-12 m-0 p-0">
                    <div className="form-quiz-upload-type">
                      <span
                        className={classNames({
                          'form-quiz-upload-browse': true,
                          'active-type': questionType === 'single',
                        })}
                        id="single"
                        onClick={handleQuestionType}
                      >
                        Single Answer
                      </span>
                      <span
                        className={classNames({
                          'form-quiz-upload-browse': true,
                          'active-type': questionType === 'multiple',
                        })}
                        id="multiple"
                        onClick={handleQuestionType}
                      >
                        Multiple Choice
                      </span>
                    </div>

                    <div className="d-flex align-items-center gap-2 my-3">
                      <InfoIcon color={'#070707'} />
                      <span className="org_form_sublabel">
                        Please choose the correct answer by clicking on the
                        answer.
                      </span>
                    </div>

                    <>
                      {/* Single Answer */}
                      <div className="d-flex flex-column gap-3 mt-1">
                        {options.map((item, index) => (
                          <>
                            <div className="d-flex align-items-center gap-3">
                              {questionType === 'single' ? (
                                <input
                                  type="radio"
                                  className="que-radio"
                                  name="answer"
                                  id={index}
                                  checked={answer.includes(
                                    options[index].value
                                  )}
                                  onClick={handleAnswer}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="que-check position-relative m-0"
                                  name=""
                                  id={index}
                                  checked={answer.includes(
                                    options[index].value
                                  )}
                                  onClick={handleAnswer}
                                />
                              )}
                              {item.type === 'image' ? (
                                <UploadImage
                                  item={item}
                                  index={index}
                                  handleOptionFiles={handleOptionFiles}
                                />
                              ) : (
                                <Input
                                  placeholder="Your Answer"
                                  type="text"
                                  autoComplete="off"
                                  id={index}
                                  className="org_form_input"
                                  valid={!sameOptionsErr && item}
                                  invalid={
                                    (isNullQuestion && !item) ||
                                    sameOptionsErr === item
                                  }
                                  value={item?.value}
                                  onChange={handleOption}
                                />
                              )}
                              {options.length > 1 && (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleRemove(index)}
                                >
                                  <CloseIcon color={'#8F8F92'} />
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </>

                    <div className="form-quiz-type my-2">
                      <div
                        className={classNames({
                          'form-quiz-type-browse': true,
                          'quiz-add-type': true,
                        })}
                        id="text"
                        onClick={() => {
                          // handleQuestionAnswer("text")
                          handleAddAnswer('text');
                        }}
                      >
                        <PlusIcon color={'#070707'} />
                        <span> Text </span>
                      </div>

                      <div
                        className={classNames({
                          'form-quiz-type-browse': true,
                          'quiz-add-type': true,
                        })}
                        id="image"
                        onClick={() => {
                          // handleQuestionAnswer("image")
                          handleAddAnswer('image');
                        }}
                      >
                        <PlusIcon color={'#070707'} />
                        <span> Image Upload </span>
                      </div>
                    </div>

                    {/* <div className="add_new_que_input" onClick={handleAddAnswer}>

                      <div className="text-center">
                        <PlusIcon color={"#070707"} />
                        <span className="add_new_title disabled">Add Text</span>
                      </div>

                      <div>
                        <PlusIcon color={"#070707"} />
                        <span className="add_new_title disabled">Add Image</span>
                      </div>
                    </div> */}
                  </div>

                  <FormGroup className="w-100">
                    <label className="org_form_label my-2 mx-0">
                      Complexity
                    </label>
                    <Select
                      name="colors"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className={classNames({
                        'basic-multi-select ': true,
                        'is-error':
                          isValidateFiled && isValidateFiled?.hasComplexity,
                        'is-success':
                          isValidateFiled && !isValidateFiled?.hasComplexity,
                      })}
                      placeholder="Select"
                      options={complexityList}
                      // className="basic-multi-select"
                      classNamePrefix="select"
                      styles={customStyles}
                      // valid={isValidateFiled && !isValidateFiled?.hasComplexity}
                      // invalid={
                      //   isValidateFiled && isValidateFiled?.hasComplexity
                      // }
                      value={complexityList.find((i) => i.value === complexity)}
                      onChange={handleComplexityChange}
                    />
                  </FormGroup>
                  <FormGroup className="w-100">
                    <label className="org_form_label my-2 mx-0">Category</label>
                    <Select
                      name="colors"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Select"
                      options={questionCategories || []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedCategory}
                      onChange={handleSelectCategory}
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <label className="org_form_label my-2 mx-0">Tags</label>
                    <Autocomplete
                      required
                      multiple
                      id="combo-box-demo"
                      filterOptions={(x) => x}
                      value={selectedTags}
                      options={tagsList}
                      getOptionLabel={(option) => option?.label}
                      onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                          setInputValue(value);
                        }
                        if (reason === 'reset') {
                          const tagsArray = tagsList;
                          const selectedTagsArray = selectedTags;
                          tagsArray.push({
                            label: inputValue,
                            value: inputValue,
                          });
                          selectedTagsArray.push({
                            label: inputValue,
                            value: inputValue,
                          });
                          setSelectedTags(selectedTagsArray);
                          setTagsList(tagsArray);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                      onChange={handleSelectTages}
                    />
                  </FormGroup>
                </div>

                <div className="my-3">
                  <button
                    type="button"
                    className="w-100 common-primary-btn"
                    onClick={handleAddQuestion}
                    disabled={isDisabled}
                  >
                    Save Question
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
        {alert.status === 'success' && alert.isOpenAlert && (
          <ConfirmationModel
            status={alert.status}
            isOpen={alert.isOpenAlert}
            confirmation={() => {
              setAlert({ isOpenAlert: false });
              setIsValidateFiled({
                questionName: false,
                hasComplexity: false,
              });
              handleModel();
            }}
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
          />
        )}
        {alert.status === 'warning' && alert.isOpenAlert && (
          <ConfirmationModel
            status={alert.status}
            isOpen={alert.isOpenAlert}
            confirmation={() => {
              setAlert({ isOpenAlert: false });
            }}
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
          />
        )}
      </Drawer>
    </>
  );
};
const UploadImage = ({ item, index, handleOptionFiles }) => {
  const [imgContact, setImgContact] = useState();
  useEffect(() => {
    setImgContact(item);
  }, [item]);
  return (
    <div className="d-flex align-items-center gap-3 w-100">
      {imgContact?.value ? (
        <div className="d-block">
          <img
            alt="que-img"
            className="question-img"
            src={imgContact?.value}
          />
        </div>
      ) : (
        //   <div>
        //    <img src={IconFile} alt="" className="add_course_form_file_icon" />
        //    <span> Choose file </span>
        //  </div>
        <></>
      )}
      <div className="que-file-input flex-1">
        <Input
          type="file"
          id={index}
          className="org_form_input"
          onChange={handleOptionFiles}
        />
        <img src={IconFile} alt="" className="add_course_form_file_icon" />
        <span className="que-choose-file">Choose File</span>
        {/* <span className='label' data-js-label>No file selected</span> */}
      </div>
    </div>
  );
};

export default AddQuestion;
