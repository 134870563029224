import {
  addCoursesAPI,
  getMyCourses,
  updateCourse,
} from 'actions/adminActions/courseAction';
import api2 from 'api2';
import axios from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { trackPromise } from 'react-promise-tracker';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Card, Container, Row } from 'reactstrap';
import RightIcon from '../../../assets/img/icons/common/icon-chevron-right.svg';
import { loaderType } from '../../../type';
import './AddCourse.css';
import CourseContent from './CourseContent';
import CourseDetails from './CourseDetails';
import CoursePublish from './CoursePublish';

const AddCourse = ({ addCoursesAction, updateCourseAction }) => {
  const { id } = useParams();
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [skip /* setSkip */] = useState(0);
  const [limit /* setLimit */] = useState(1000);
  const [isValidateFiled, setIsValidateFiled] = useState();
  const [isDisabled, setIsDisabled] = useState();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpen: false,
  });
  const myCourses = useSelector((state) => state?.courses?.myCourses);
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const course = useSelector((state) => state?.courses);
  const [pageView, setPageView] = useState('courseDetail');
  const [addCourse, setAddCourse] = useState(course);
  const handlePageView = (name) => {
    setShowError(true);
    let valid = validationFun();
    if (valid) {
      setPageView(name);
    }
  };
  const fetchCourse = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      let result = await api2.get(`api/course/${id}`, config);
      setAddCourse(result?.data?.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  useEffect(() => {
    let courseData = '';
    if (id) {
      fetchCourse();
      // courseData = myCourses.list.find((item) => item._id === id);
    } else {
      courseData = course;
    }
    setAddCourse(courseData);
  }, []);
  useEffect(() => {
    if (!id) {
      setAddCourse({
        whatYouWillLearn: [''],
        requirements: [''],
        courseContents: [
          {
            title: '',
            contents: [
              {
                title: '',
                type: 'video',
                link: '',
                duration: 0,
              },
            ],
          },
        ],
      });
    }
  }, []);
  useEffect(() => {
    if (showError) {
      validationFun();
    }
    dispatch({ type: 'ADD_COURSE_DATA', payload: '', addCourse });
  }, [addCourse]);
  const saveCourse = () => {
    setShowError(true);
    let valid = validationFun();
    if (valid) {
      addUpdateCourse();
    }
  };
  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + ' ' + sizes[i];
    return parseInt(bytes / Math.pow(1024, i)).toFixed(1);
  }
  const validationFun = () => {
    let courseNameValid = false;
    let subtitles = false;
    let whatYouWillLearn = false;
    let requirements = false;
    let whoIsThisFor = false;
    let thumbnail = false;
    let coverImage = false;
    if (!addCourse?.courseName) {
      courseNameValid = true;
    }
    if (!addCourse?.subtitles) {
      subtitles = true;
    }
    if (
      addCourse?.whatYouWillLearn?.length &&
      !addCourse?.whatYouWillLearn[0]
    ) {
      whatYouWillLearn = true;
    }
    if (addCourse?.requirements?.length && !addCourse?.requirements[0]) {
      requirements = true;
    }
    if (!id && !addCourse?.whoIsThisFor) {
      whoIsThisFor = true;
    }
    if (!id && !addCourse?.thumbnail) {
      thumbnail = true;
    }
    if (!addCourse?.coverImage) {
      coverImage = true;
    }
    if (typeof addCourse?.thumbnail === 'object') {
      let size = parseFloat(addCourse?.thumbnail?.size);
      thumbnail = bytesToSize(size) > 1024;
    }
    if (typeof addCourse?.coverImage === 'object') {
      let size = parseFloat(addCourse?.coverImage?.size);
      coverImage = bytesToSize(size) > 1024;
    }
    setIsValidateFiled({
      ...isValidateFiled,
      courseNameValid,
      subtitles,
      whatYouWillLearn,
      requirements,
      whoIsThisFor,
      thumbnail,
      coverImage,
    });
    if (
      !courseNameValid &&
      !subtitles &&
      !whoIsThisFor &&
      !thumbnail &&
      !coverImage
    ) {
      return true;
    }
  };
  const addUpdateCourse = () => {
    setIsDisabled(true);
    const handleData = () =>
      id ? updateCourseAction(addCourse, id) : addCoursesAction(addCourse);
    handleData().then((res) => {
      setIsDisabled(false);
      setAlert({
        ...alert,
        isOpen: true,
        status: 'success',
        message: id
          ? 'Course update successfully!'
          : 'Course added successfully!',
      });
    });
  };
  useEffect(() => {
    if (id) {
      trackPromise(dispatch(getMyCourses(skip, limit)));
    }
  }, [dispatch, skip, limit]);
  const handleCancel = () => {
    history.push('/allcourse');
  };
  const handleConfirm = () => {
    setAlert({
      ...alert,
      isOpen: false,
    });
    handleCancel();
  };
  const handlePublish = () => {
    let valid = validationFun(addCourse);
    if (valid) {
      setAlert({
        ...alert,
        isOpen: true,
        status: 'warning',
        message: 'Are you sure you want to publish',
      });
    }
  };
  const handleConfirmPublished = () => {
    let valid = validationFun();
    if (valid) {
      addCourse.isDraft = false;
      addCourse.isPublish = true;
      addUpdateCourse();
    }
  };

  return (
    <>
      <Container className="mt-2 " fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper cardContainer">
              <Card className="p-lg-4 ">
                <div className="add_course_page_header">
                  <div className="d-flex gap-3 align-items-center">
                    <h5 className="add_course_page_title">
                      {!id ? 'Create' : 'Edit'} a course
                    </h5>
                    {addCourse?.isDraft && (
                      <span className="course_daft_badge">Draft</span>
                    )}
                  </div>

                  <div className="d-flex gap-1 align-items-center">
                    <button
                      className="add_course_cancel_btn"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    {addCourse?.isDraft !== false && (
                      <button
                        className="add_course_save_btn"
                        disabled={isDisabled}
                        onClick={() => {
                          addCourse.isDraft = true;
                          saveCourse();
                        }}
                      >
                        Save Draft
                      </button>
                    )}

                    <button
                      className="add_course_publish_btn"
                      disabled={isDisabled}
                      onClick={handlePublish}
                    >
                      Publish
                    </button>
                  </div>
                </div>

                <div className="add_course_pages">
                  <div
                    className="add_course_pages_name_wrapper"
                    onClick={() => handlePageView('courseDetail')}
                  >
                    <span
                      className={classNames('add_course_pages_count', {
                        add_course_pages_count_active:
                          pageView === 'courseDetail',
                      })}
                    >
                      1
                    </span>
                    <span className="add_course_pages_name" id="courseDetail">
                      Course Detail
                    </span>
                  </div>
                  <img src={RightIcon} alt="" />

                  <div
                    className="add_course_pages_name_wrapper"
                    onClick={() => handlePageView('courseContent')}
                  >
                    <span
                      className={classNames('add_course_pages_count', {
                        add_course_pages_count_active:
                          pageView === 'courseContent',
                      })}
                    >
                      2
                    </span>
                    <span className="add_course_pages_name" id="courseContent">
                      Course Content
                    </span>
                  </div>
                  <img src={RightIcon} alt="" />

                  <div
                    className="add_course_pages_name_wrapper"
                    onClick={() => handlePageView('publish')}
                  >
                    <span
                      className={classNames('add_course_pages_count', {
                        add_course_pages_count_active: pageView === 'publish',
                      })}
                    >
                      3
                    </span>
                    <span className="add_course_pages_name" id="publish">
                      Publish
                    </span>
                  </div>
                </div>
                {pageView === 'courseDetail' && (
                  <CourseDetails
                    addCourse={addCourse}
                    setAddCourse={setAddCourse}
                    setPageView={setPageView}
                    isValidateFiled={isValidateFiled}
                    setIsValidateFiled={setIsValidateFiled}
                    validationFun={validationFun}
                    setShowError={setShowError}
                  />
                )}

                {pageView === 'courseContent' && (
                  <CourseContent
                    addCourse={addCourse}
                    setAddCourse={setAddCourse}
                    setPageView={setPageView}
                  />
                )}

                {pageView === 'publish' && (
                  <CoursePublish
                    addCourse={addCourse}
                    setAddCourse={setAddCourse}
                    setPageView={setPageView}
                  />
                )}
              </Card>
            </div>
          </div>
          {alert?.status === 'success' && alert?.isOpen === true && (
            <SweetAlert
              success
              title={
                <span
                  style={{
                    fontSize: '24px',
                  }}
                >
                  {alert?.message}{' '}
                </span>
              }
              onConfirm={handleConfirm}
            />
          )}
          {alert?.status === 'warning' && alert?.isOpen === true && (
            <SweetAlert
              warning
              title={
                <span
                  style={{
                    fontSize: '24px',
                  }}
                >
                  {alert?.message}{' '}
                </span>
              }
              onConfirm={() => {
                setIsDisabled(true);
                if (!isDisabled) {
                  handleConfirmPublished();
                }
              }}
            />
          )}
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // groups: state.groups,
    // users: state.organization.userData,
    // profile: state.authState.user,
    // plans: state.plans.plans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCourseAction: (data, id) =>
      trackPromise(
        dispatch(updateCourse(data, id)),
        loaderType.GENERAL_REQUEST
      ),
    addCoursesAction: (data) => dispatch(addCoursesAPI(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCourse);
