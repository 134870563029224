import React from "react";

import { Route, Switch } from "react-router-dom";
import classnames from "classnames";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";
import LoadingIndicator from "../components/LoadingIndicator";
import { loaderType } from "../type";
import AuthVerify from "../components/AuthVerify";

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
  };

  componentDidMount() {
    const { profile } = this.props;
    if (profile && profile.accessToken) {
      if (
        profile.userType === "admin" &&
        this.props.location.pathname === "/"
      ) {
        // this.props.history.push("/categories")
        this.props.history.push("/users");
      }
    }
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (this.refs.mainContent) {
        this.refs.mainContent.scrollTop = 0;
      }
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "admin") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };
  render() {
    const { profile } = this.props;
    if (profile) {
    } else {
      let redirectPath;
      if (this.props && this.props.history && this.props.history.location) {
        redirectPath = this.props.history.location.pathname;
      }
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              redirectPath,
            },
          }}
        />
      );
    }

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/categories",
            imgSrc: require("assets/img/brand/cyberange-logo.png"),
            imgAlt: "...",
          }}
        />
        <div
          className="main-content my-3"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <div className="d-xl-none position-relative ">
            <div
              style={{
                zIndex: "9999",
                position: "absolute",
                right: "20px",
                top: "20px",
                background: "#fff",
                padding: "15px",
                borderRadius: "50%",
                height: "48px",
                width: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "20px 20px 50px grey",
              }}
              className={classnames(
                "pr-3 sidenav-toggler",
                { active: this.sidenavOpen },
                {
                  "sidenav-toggler-dark": this.theme === "dark",
                }
              )}
              onClick={this.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
          {/* <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          /> */}
          <Switch>{this.getRoutes(routes)}</Switch>
          <AdminFooter />
        </div>
        <LoadingIndicator area={loaderType.GENERAL_REQUEST} />
        {this.props.loggedIn && <AuthVerify />}
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authState.loggedIn,
    profile: state.authState.user,
  };
};
export default connect(mapStateToProps, {})(Admin);
