import React, { useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Link, MenuItem, Typography } from "@mui/material";
import { getAllCategory } from "actions/adminActions/categoriesActions";
import { Badge, Card, CardHeader, Container } from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import SweetAlert from "react-bootstrap-sweetalert";

// import "./Quiz.css";
import { useState } from "react";
import MuiOverride from "components/Common/MuiOverride";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteCategory } from "actions/adminActions/categoriesActions";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "type";
import classNames from "classnames";

const Categories = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [catId, setCatId] = useState(null);

  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });
  const categories = useSelector((state) => state.categories.categories);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "fullName",
        Cell: ({ row }) => (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                cursor:"pointer",
                color:"var(--primary)"
              }}
              onClick={()=>history.push(`editCategories/${row?.original?.id}`)}
            >
              <Typography className="">{row?.original?.fullName}</Typography>
            </Box>
          </>
        ),
      },

      {
        header: "Short Name",
        accessorKey: "shortName",
        Cell: ({ row }) => (
          <>
            {" "}
            <Typography className="">{row?.original?.shortName}</Typography>
          </>
        ),
      },
      {
        header: "ID",
        accessorKey: "_id",
        Cell: ({ row }) => (
          <Typography className="">{row?.original?._id}</Typography>
        ),
      },
      {
        header: "Description",
        Cell: ({ row }) => (
          <Typography className="tbl-description">
            {row?.original?.description}
          </Typography>
        ),
      },
      {
        header: "Type",
        Cell: ({ row }) => (
          <Badge color="" className="badge-dot mr-4">
            <i
              className={classNames(
                { "bg-success": row?.original?.isPublic },
                { "bg-warning": !row?.original?.isPublic }
              )}
            />
            <span
              className={classNames(
                "status",
                { "text-success": row?.original?.isPublic },
                { "text-warning": !row?.original?.isPublic }
              )}
            >
              {row?.original?.isPublic && "Public"}
              {!row?.original?.isPublic && "Private"}
            </span>
          </Badge>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };

  useEffect(() => {
    trackPromise(dispatch(getAllCategory(0, 3000)), loaderType.GENERAL_REQUEST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const confirmAlert = () => {
    // setAlert({ ...alert, isOpenAlert: false });
    dispatch(deleteCategory(catId));
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "success",
      message: "Category deleted successfully.",
    });
  };

  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Categories</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => history.push("/addCategories")}
              >
                <PlusIcon color="white" />
                Add Categories
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={categories || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActionMenuItems={({ row }) => [
              <>
                <MenuItem
                  key={1}
                  onClick={(e) => {
                    history.push(`editCategories/${row?.original?.id}`);
                  }}
                  sx={{ m: 0 }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  key={1}
                  onClick={() => {
                    handleDelete();
                    setCatId(row?.original?._id);
                  }}
                  sx={{ m: 0 }}
                >
                  Delete
                </MenuItem>
              </>,
            ]}
            // renderRowActions={({ row, table }) => (
            //   <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
            //     <button className="table-action-edit-btn">
            //       <EditIcon color={"#070707"} />
            //       Edit
            //     </button>
            //     <button
            //       type="button"
            //       className="table-action-delete-btn"

            //     >
            //       <SmTrashIcon color={"#EF466F"} />
            //       Delete
            //     </button>
            //   </Box>
            // )}
          />
        </MuiOverride>
      </Card>
      {alert.status === "warning" && alert.isOpenAlert && (
        <SweetAlert
          warning
          title={
            <span
              style={{
                fontSize: "24px",
              }}
            >
              {alert?.message}{" "}
            </span>
          }
          onConfirm={confirmAlert}
          showCancel
          confirmBtnBsStyle="danger"
          onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
      {alert.status === "success" && alert.isOpenAlert && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: "24px",
              }}
            >
              {alert?.message}{" "}
            </span>
          }
          onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
        />
      )}
    </Container>
  );
};

export default Categories;
