/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
import "./sidebar.css";
import logoutAction from "actions/logoutAction";
import { connect } from "react-redux";
import { compose } from "redux";
import OrgLogo from "../../assets/img/brand/Upskillr.png";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import classNames from "classnames";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      windowWidth: false,
      ...this.getCollapseStates(props.routes),
    };
  }

  // verifies if routeName is the one active (in browser input)
  componentDidMount() {
    if (window.innerWidth < 1200) {
      this.setState({ collapseOpen: true });
    } else {
      this.setState({ collapseOpen: false });
    }
    const handleWindowResize = () => {
      this.setState({ windowWidth: window.innerWidth });
    };

    window.addEventListener("resize", this.handleWindowResize);

    return () => {
      window.removeEventListener("resize", this.handleWindowResize);
    };
  }
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    // routes.map((prop, key) => {
    //   if (prop.collapse) {
    //     initialState = {
    //       [prop.state]: this.getCollapseInitialState(prop.views),
    //       ...this.getCollapseStates(prop.views),
    //       ...initialState,
    //     };
    //   }
    //   return null;
    // });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];

        return (
          prop.visible && (
            <NavItem key={key}>
              <NavLink
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={this.state[prop.state]}
                className={classnames({
                  active: this.getCollapseInitialState(prop.views),
                })}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(st);
                }}
              >
                {prop.icon ? <i className={prop.icon} /> : null}
                <span className="nav-link-text">{prop.name}</span>
              </NavLink>
              <Collapse isOpen={this.state[prop.state]}>
                <Nav className="nav-sm flex-column">
                  {this.createLinks(prop.views)}
                </Nav>
              </Collapse>
            </NavItem>
          )
        );
      }

      return (
        prop.visible && (
          <NavItem className={this.activeRoute(prop.path)} key={key}>
            <NavLink
              to={prop.path}
              exact
              activeClassName=""
              onClick={this.closeSidenav}
              tag={NavLinkRRD}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : (
                <span className="nav-link-text">{prop.name}</span>
              )}
            </NavLink>
          </NavItem>
        )
      );
    });
  };
  render() {
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }

    return (
      <Navbar
        // className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
        // className="sidenav navbar navbar-vertical navbar-main-wrapper navbar-expand-xs border-0 p-0 border-radius-xl bg-white my-3 fixed-start d-flex flex-column ms-4 "
        // expand="md"
        className={classNames(
          `sidenav navbar navbar-vertical navbar-expand-xs bg-white border-0 border-radius-xl 
          my-3 fixed-start d-flex flex-column px-0 overflow-x-hidden`,
          { "ms-4": !this.props.sidenavOpen || !this.state.collapseOpen },
          { "ms-0": this.props.collapseOpen }
        )}
        // className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 d-flex flex-column"
        expand="md"
        id="sidenav-main"
      >
        <div
          className="collapse navbar-collapse w-100 h-auto mx-0 px-md-1 px-0 overflow-x-hidden"
          id="sidenav-main"
        >
          {logo ? (
            <NavbarBrand {...navbarBrandProps} className="m-0 py-0 px-3 ">
              <img
                src={OrgLogo}
                alt={logo.imgAlt}
                className="navbar-brand-img sidebar-brand-img"
              />
            </NavbarBrand>
          ) : null}
          <hr className="horizontal dark" />
          <Nav className=" mx-0" navbar>
            {this.createLinks(routes)}
          </Nav>
          <div className="flex-grow-org" />
          <ul className="my-5 navbar-nav cursor-pointer mx-0 ">
            <li className="nav-item" onClick={this.props.logoutAction}>
              <div className="nav-link">
                <i className="ni ni-user-run" />
                <span className="nav-link-text">Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
};
const mapStateToProps = (state) => {
  return {
    profile: state.authState.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(logoutAction()),
});
Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Sidebar);
