import API from "api2";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const createUser = (state) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        // url: `auth/register`,
        url: `auth/addUser`,
        headers: config(token),
        data: {
          name: state.name,
          email: state.email,
          password: state.password,
          phoneNumber: state.phoneNumber,
          sendEmail: state.sendEmail,
          userType: state.userType,
          tabAccess: state.tabAccess,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'CREATE_USER_SUCCESS' });
          resolve({ success: true, message: data.message, data });
        })
        .catch((err) => {
          let errData =
            (err.response && err.response.data) ||
            dispatch({ type: 'CREATE_USER_ERROR' }, err);
          reject(errData);
        });
    });
  };
};

export const getAllUser = (skip, limit, userType) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let userTypeQuery = '';
    if (userType) {
      userTypeQuery = userType;
    } else {
      userTypeQuery = 'individual';
    }
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/user?userType=${userTypeQuery}&skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          const userlist = res.data.data.list;

          const newlist = userlist.map((x) => {
            if (x.liceAssignedDetails) {
              const sLicence = x.liceAssignedDetails.map((licence) => {
                return licence?.licenseId?.name;
              });
              x['licenseIds'] = sLicence.toString();
              return x;
            } else {
              x['licenseIds'] = '';
              return x;
            }
          });
          dispatch({
            type: 'FETCH_ALL_USER_SUCCESS',
            payload: true,
            // data: res.data.data.list,
            data: newlist,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_ALL_USER_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};

export const getAdminAddedUser = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/user/addedByAdmin?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          const userlist = res.data.data.list;
          const newlist = userlist.map((x) => {
            if (x.licenseIds) {
              const slicence = x.licenseIds.map((licence) => {
                return licence.name;
              });
              x['licenseIdsName'] = slicence.toString();
              return x;
            } else {
              x['licenseIdsName'] = '';
              return x;
            }
          });
          dispatch({
            type: 'FETCH_ADMIN_ADDED_USER_SUCCESS',
            payload: true,
            // data: res.data.data.list,
            data: newlist,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_ADMIN_ADDED_USER_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};

export const getUserStatistics = (id) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/userStatistics?userId=${id}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: 'FETCH_USERS_STATISTICS_SUCCESS',
            payload: true,
            data: res.data.data,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_USERS_STATISTICS_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};
export const fetchAllFreeLab = () => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API.get(`/api/wlabs-challenges/list`, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'FETCH_FREE_LAB_SUCCESS', payload: true, data });
          resolve(data.data);
        })
        .catch((err) => {
          dispatch({ type: 'FETCH_FREE_FAILED', payload: true });
          reject();
        });
    });
  };
};

export const editUser = (userId, state) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/user/organisation/${userId}/details`,
        headers: config(token),
        data: {
          name: state.name,
          email: state.email,
          tabAccess: state.tabAccess,
          phoneNumber: state.phoneNumber,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_USER_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errData =
            (err.response && err.response.data) ||
            dispatch({ type: 'UPDATE_USER_ERROR', err });
          reject({ success: false, message: errData.message });
        });
    });
  };
};

export const updateUser = (objectToAdd, licenseID) => {
  return async (dispatch, getState, { getFirestore }) => {
    //var objectsToAdd = ["0MaMJ1Fr1Rb2lMGrwJAoKAdjghk2","0og8ltfwQRdvu7XJF2SJb3xmft12","0Tj9UNE4dEc8Qc7c76IL7OYBRdE2" ]
    const firestore = getFirestore();
    let collectionRef = firestore.collection(`vcr/db/users`);
    const batch = firestore.batch();
    objectToAdd.forEach((obj) => {
      const newDocRef = collectionRef.doc(obj);
      batch.update(newDocRef, {
        license_enforced: true,
        license_id: licenseID,
      });
    });
    return await batch.commit();
  };
};

export const deleteUser = (userId, actionType) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: "delete",
        url: `/api/user/${userId}?actionType=${actionType}`,
        headers: config(token),
      })
        .then((res) => {
          resolve(res?.data);
          dispatch(getAllUser(0, 2000));
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          reject({ success: false, message: errMessage });
        });
    });
  };
};

export const toggleUserStatus = ({ uid, status }) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let users = getState().users.userData;
    let userStatus = !status ? "disable" : "enable";
    return new Promise(function (resolve, reject) {
      API({
        method: "put",
        url: `api/user/${uid}/${userStatus}`,
        headers: config(token),
      })
        .then((res) => {
          for (let u of users) {
            if (u.id === uid) {
              u.isActive = !status ? true : false;
            }
          }
          const { data } = res;
          dispatch({ type: "USER_STATUS_SUCCESS", payload: true, data: users });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            "Unknown Error Occured";
          dispatch({ type: "USER_STATUS_FAILED" }, err);
          reject({ success: false, message: errMessage });
        });
    });
  };
};

export const toggleInterviewMode = ({ uid, status }) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let users = getState().users.userData;
    let userStatus = status ? "disableInterviewMode" : "enableInterviewMode";
    API({
      method: "put",
      url: `api/user/${uid}/${userStatus}`,
      headers: config(token),
    })
      .then(() => {
        for (let u of users) {
          if (u.id === uid) {
            u.isInterviewMode = !status ? true : false;
          }
        }
        dispatch({
          type: "USER_INTERVIEWMODE_SUCCESS",
          payload: true,
          data: users,
        });
      })
      .catch((err) => {
        dispatch({ type: "USER_INTERVIEWMODE_FAILED" }, err);
      });
  };
};

export const updateUserOrganisationPassword = (id, password) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    const data = {
      password: password,
    };

    return new Promise(function (resolve, reject) {
      API.put(`/api/user/organisation/${id}/updatePassowrd`, data, {
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "UPDATE_USER_PASSWORD_SUCCESS",
            payload: true,
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const { message } = err.response.data;
          dispatch({
            type: "UPDATE_USER_PASSWORD_FAILED",
            payload: true,
            message,
          });
          reject(message);
        });
    });
  };
};

export const uploadCsv = (file, type = "organisation") => {
  return (dispatch, getState) => {
    const formdata = new FormData();
    const isOrganisation = type === "organisation";
    formdata.append("csv", file.file);
    formdata.append("organisationId", file.orgid);

    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API.post(
        `/api/user/${isOrganisation ? "organisation" : "individual"}/import`,
        formdata,
        { headers: config(token) }
      )
        .then((res) => {
          const { data } = res;
          dispatch({ type: "UPLOAD_USER_CSV_SUCCESS", payload: true, data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err.response.data.message;
          dispatch({ type: "UPLOAD_USER_CSV_FAILED", payload: true, err });
          reject(errorMessage);
        });
    });
  };
};

export const updateUsers = (data, id) => {
  return (dispatch, getState) => {
    if (data.isActive === "") {
      delete data.isActive;
    }
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API.put(`/api/user/organisation/${id}/details`, data, {
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: "UPDATE_USER_SUCCESS", payload: true, data });
          resolve(data);
        })
        .catch((err) => {
          const { message } = err.response.data;
          dispatch({ type: "UPDATE_USER_FAILED", payload: true, message });
          reject(message);
        });
    });
  };
};

export const verifyUserEmail = ({ id, email }) => {
  return (dispatch, getState) => {
    const data = {
      email: email,
    };
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API.put(`/api/user/${id}/verifyEmail`, data, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({ type: "UPDATE_USER_SUCCESS", payload: true, data });
          resolve(data);
        })
        .catch((err) => {
          const { message } = err.response.data;
          dispatch({ type: "UPDATE_USER_FAILED", payload: true, message });
          reject(message);
        });
    });
  };
};

export const toggleExamMode = ({ uid, status }) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    let users = getState().users.userData;
    let userStatus = status ? "enableExamMode" : "disableExamMode";
    return new Promise((resolve, reject) => {
      API({
        method: "put",
        url: `api/user/${uid}/${userStatus}`,
        headers: config(token),
      })
        .then((res) => {
          for (let u of users) {
            if (u._id === uid) {
              u.isExamMode = !status ? true : false;
            }
          }
          dispatch({
            type: "USER_EXAMMODE_SUCCESS",
            payload: true,
            data: users,
          });
          resolve({ ...res.data, message: "Success" });
        })
        .catch((err) => {
          dispatch({ type: "USER_EXAMMODE_FAILED" }, err);
          reject({ ...err, message: "Something went wrong" });
        });
    });
  };
};
