import React, { useEffect, useRef } from "react";
import {
  Form,
  Input,
  Row,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import CheckIcon from "../../../assets/img/icons/common/icon-check.svg";
import PlusIcon from "../../../assets/img/icons/common/icon-plus.svg";
import DoubleRightIcon from "../../../assets/img/icons/common/icon-double-chevron-right.svg";
import ExamImg from "../../../assets/img/theme/exam.png";
import NewCustomDropzon from "components/Common/NewCustomDropzon";
import { MdPhotoCamera } from "react-icons/md";
import ReactQuill from "react-quill";
import Select from "react-select";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import classNames from "classnames";
import InfoIcon from "iconComponents/InfoIcon";
import { trackPromise } from "react-promise-tracker";
import { getAllCourseCategory } from "actions/adminActions/courseAction";
import { loaderType } from "type";
import { useSelector } from "react-redux";

const CourseDetails = ({
  addCourse,
  setAddCourse,
  setPageView,
  isValidateFiled,
  setIsValidateFiled,
  validationFun,
  setShowError,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [courseCategoryList, setCourseCategoryList] = useState([]);
  const { courseCategory } = useSelector((state) => state?.categories);
  const thumbnailRef = useRef()
  const coverRef = useRef()



  const [customersList, setCustomersList] = useState([
    {
      label: "Customers",
      value: "customers",
    },
    {
      label: "Students",
      value: "Students",
    },
  ]);

  useEffect(() => {
    trackPromise(
      dispatch(getAllCourseCategory(0, 500)),
      loaderType.GENERAL_REQUEST
    );
  }, []);
  useEffect(() => {
    let courseCategoryArray = [courseCategory];
    courseCategoryArray =
      courseCategory?.length &&
      courseCategory.map((item) => {
        return {
          label: item?.name,
          value: item._id,
        };
      });
    setCourseCategoryList(courseCategoryArray || []);
  }, [courseCategory]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddCourse({
      ...addCourse,
      [name]: value,
    });
  };
  const handleSelect = (value) => {
    setAddCourse({
      ...addCourse,
      categoryId: value?.value,
    });
  };
  const handleUpload = async (file, name) => {
    setIsValidateFiled({
      ...isValidateFiled,
      [name]: false,
    });
    if (
      file?.name.split(".")[1] === "png" ||
      file?.name.split(".")[1] === "jpg"
    ) {
      if (file.size > 2 * 1024 * 1024) {
        setIsValidateFiled({
          ...isValidateFiled,
          [name]: true,
        });
      } else {
        let height = 0;
        let width = 0;
        if (name === "thumbnail") {
          height = 512;
          width = 512;
        } else {
          height = 696;
          width = 1176;
        }
        const img = new Image();
        img.src = URL.createObjectURL(file);
        new Promise(async (resolve, reject) => {
          img.onload = () => {
            if (img.naturalHeight <= height || img.naturalWidth <= width) {
              setIsValidateFiled({
                ...isValidateFiled,
                [name]: false,
              });
              setAddCourse({
                ...addCourse,
                [name]: file,
              });
            } else {
              setIsValidateFiled({
                ...isValidateFiled,
                [name]: true,
              });
            }
            resolve({ width, height });
          };
        });
      }
    } else {
      setIsValidateFiled({
        ...isValidateFiled,
        [name]: true,
      });
    }
  };
  const handleAddPoint = () => {
    addCourse.whatYouWillLearn.push("");
    setAddCourse({
      ...addCourse,
      whatYouWillLearn: addCourse.whatYouWillLearn,
    });
  };
  const handleAddRequirement = () => {
    addCourse.requirements.push("");
    setAddCourse({
      ...addCourse,
      requirements: addCourse.requirements,
    });
  };
  // const handleSelect = (value) => {
  //   setAddCourse({
  //     ...addCourse,
  //     customersList: value
  //   })
  // }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }
  const handleRemove = (e, index) => {
    const name = e.target?.id;
    if (name === "whatYouWillLearn") {
      addCourse?.whatYouWillLearn.splice(index, 1);
    } else {
      addCourse?.requirements.splice(index, 1);
    }
    setAddCourse({
      ...addCourse,
    });
  };
  const handleContinue = () => {
    setShowError(true);
    let valid = validationFun();
    if (valid) {
      setPageView("courseContent");
    }
  };
  return (
    <>
      <Row>
        <Form role="form" className="add_course_form w-100">
          <div className="col-md-12 ">
            <div className="course-img-wrapper position-relative">
              {/* Thumbnail */}
              <div className="position-relative d-flex flex-column ">
                <div className="course-thumbnail-wrapper">
                  {!addCourse?.thumbnail ? (
                    <NewCustomDropzon
                      name="thumbnail"
                      handleUpload={handleUpload}
                      type="img"
                      imageType="Thumbnail"
                    />
                  ) : (
                    <>
                      <img
                        className="course-banner-img"
                        src={
                          (addCourse?.thumbnail?.name &&
                            URL.createObjectURL(addCourse?.thumbnail)) ||
                          addCourse?.thumbnail
                        }
                        alt=""
                      />

                      <div
                        className="course-thumbnail-edit"
                        onClick={() => thumbnailRef.current.click()}
                      >
                        <MdPhotoCamera
                          style={{
                            height: '20px',
                            width: '20px',
                            fill: 'var(--primary)',
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="d-flex gap-2 my-2 position-absolute course-thumbnail-info">
                  <InfoIcon
                    color={
                      isValidateFiled && isValidateFiled?.thumbnail
                        ? '#ff0000'
                        : '#070707'
                    }
                  />
                  <span
                    className={classNames({
                      add_course_form_sublabel: true,
                      'error-message':
                        isValidateFiled && isValidateFiled?.thumbnail,
                    })}
                  >
                    File formats should be PNG or JPG, Max 2MB
                    <br />
                    Thumbnail size upto 512px x 512px
                  </span>
                </div>
              </div>
              <input
                className="d-none"
                type="file"
                ref={thumbnailRef}
                onChange={(e) => handleUpload(e.target.files[0], 'thumbnail')}
              />
              <input
                className="d-none"
                type="file"
                ref={coverRef}
                onChange={(e) => handleUpload(e.target.files[0], 'coverImage')}
              />

              {/* Banner */}
              <div>
                <div className="course-banner-wrapper">
                  {!addCourse?.coverImage ? (
                    <NewCustomDropzon
                      name="coverImage"
                      handleUpload={handleUpload}
                      type="img"
                      imageType="Cover"
                    />
                  ) : (
                    <>
                      <img
                        className="course-banner-img"
                        src={
                          (addCourse?.coverImage?.name &&
                            URL.createObjectURL(addCourse?.coverImage)) ||
                          addCourse?.coverImage
                        }
                        alt=""
                      />

                      <div
                        className="course-banner-edit"
                        onClick={() => {
                          coverRef.current.click();
                        }}
                      >
                        <MdPhotoCamera
                          style={{
                            height: '20px',
                            width: '20px',
                            fill: 'var(--primary)',
                          }}
                        />
                        <span>Edit banner photo</span>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-end gap-2 my-2">
                  <InfoIcon
                    color={
                      isValidateFiled && isValidateFiled?.coverImage
                        ? '#ff0000'
                        : '#070707'
                    }
                  />
                  <span
                    className={classNames({
                      add_course_form_sublabel: true,
                      'error-message':
                        isValidateFiled && isValidateFiled?.coverImage,
                    })}
                  >
                    File formats should be PNG or JPG, Max 2MB
                    <br />
                    Banner Size upto 1176px x 696px
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-5">
            {/* Course Title */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Course Title
              </label>
              <Input
                placeholder="Enter Course Title"
                type="text"
                autoComplete="off"
                name="courseName"
                value={addCourse?.courseName}
                valid={isValidateFiled && !isValidateFiled?.courseNameValid}
                invalid={isValidateFiled && isValidateFiled?.courseNameValid}
                className="add_course_form_input"
                onChange={handleChange}
              />
            </FormGroup>
            {/* Sub Title */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Sub Title
              </label>
              <Input
                placeholder="Enter Sub Title"
                type="text"
                autoComplete="off"
                name="subtitles"
                value={addCourse?.subtitles}
                valid={isValidateFiled && !isValidateFiled?.subtitles}
                invalid={isValidateFiled && isValidateFiled?.subtitles}
                className="add_course_form_input"
                onChange={handleChange}
              />
            </FormGroup>
            {/* Course Category */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Course Category
              </label>
              <Select
                name="colors"
                options={courseCategoryList}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  courseCategoryList &&
                  courseCategoryList.length &&
                  courseCategoryList.find(
                    (item) => item?.value === addCourse?.categoryId?._id
                  )
                }
                onChange={handleSelect}
              />
            </FormGroup>
            {/* Description */}
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Description
              </label>
              <ReactQuill
                theme="snow"
                placeholder="Add your course detail.."
                value={addCourse?.description}
                onChange={(value) => {
                  let obj = {
                    ...addCourse,
                    description: value,
                  };
                  setAddCourse(obj);
                }}
              />
            </FormGroup>

            {/* Learn Point */}
            <FormGroup className="my-4 d-flex flex-column">
              <label className="add_course_form_label my-2 mx-0">
                Course Outcomes
              </label>
              <span className="add_course_form_label_second">
                What your will learn on this course
              </span>

              <div>
                {addCourse?.whatYouWillLearn?.length &&
                  addCourse?.whatYouWillLearn.map((item, index) => (
                    <>
                      <InputGroup
                        className={classNames({
                          'input-group-merge input-group-alternative input-group-alternative-styled mb-3': true,
                          'is-error':
                            isValidateFiled &&
                            isValidateFiled?.whatYouWillLearn,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="input-addon">
                            <img src={CheckIcon} alt="" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Add Course Outcome"
                          type="text"
                          value={item}
                          className="input-addon"
                          onChange={(e) => {
                            let allPoints = addCourse.whatYouWillLearn;
                            allPoints[index] = e.target.value;
                            setAddCourse({
                              ...addCourse,
                              whatYouWillLearn: allPoints,
                            });
                          }}
                        />
                        {addCourse?.whatYouWillLearn.length > 1 && (
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              className="input-addon cursor-pointer"
                              onClick={(e) => handleRemove(e, index)}
                            >
                              <i
                                className="far fa-times-circle no-top-i"
                                id="whatYouWillLearn"
                              ></i>
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </>
                  ))}

                <div
                  className="course_add_requirement_input"
                  onClick={handleAddPoint}
                >
                  <img src={PlusIcon} alt="" />
                  <span className="course_add_requirement_title">
                    Add Learn Point{' '}
                  </span>
                </div>
              </div>
            </FormGroup>

            {/* Requirement */}
            <FormGroup className="my-4 d-flex flex-column">
              <label className="add_course_form_label my-2 mx-0">
                Requirements
              </label>
              <span className="add_course_form_label_second">
                Minimum requirement before buy this course
              </span>

              <div>
                <div>
                  {addCourse?.requirements?.length &&
                    addCourse?.requirements.map((item, index) => (
                      <>
                        <InputGroup
                          className={classNames({
                            'input-group-merge input-group-alternative input-group-alternative-styled mb-3': true,
                            'is-error':
                              isValidateFiled && isValidateFiled?.requirements,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="input-addon">
                              <img src={CheckIcon} alt="" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Add Requirements "
                            type="text"
                            value={item}
                            className="input-addon"
                            onChange={(e) => {
                              let allPoints = addCourse.requirements;
                              allPoints[index] = e.target.value;
                              setAddCourse({
                                ...addCourse,
                                requirements: allPoints,
                              });
                            }}
                          />
                          {addCourse?.requirements.length > 1 && (
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                className="input-addon cursor-pointer"
                                onClick={(e) => handleRemove(e, index)}
                              >
                                <i
                                  className="far fa-times-circle no-top-i"
                                  id="requirements"
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          )}
                        </InputGroup>
                      </>
                    ))}
                </div>

                <div
                  className="course_add_requirement_input"
                  onClick={handleAddRequirement}
                >
                  <img src={PlusIcon} alt="" />
                  <span className="course_add_requirement_title disabled">
                    Add Requirement
                  </span>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Course Audience (Comma Separated)
              </label>
              <Input
                placeholder="Who is this course for?"
                type="text"
                autoComplete="off"
                name="whoIsThisFor"
                value={addCourse?.whoIsThisFor}
                valid={isValidateFiled && !isValidateFiled?.whoIsThisFor}
                invalid={isValidateFiled && isValidateFiled?.whoIsThisFor}
                className="add_course_form_input"
                onChange={handleChange}
              />
            </FormGroup>
            {/* <FormGroup className="my-4">
              <label className="add_course_form_label my-2 mx-0">
                Course Category
              </label>
              <Select
                name="colors"
                options={courseCategoryList}
                className="basic-multi-select"
                classNamePrefix="select"
                value={
                  courseCategoryList &&
                  courseCategoryList.length &&
                  courseCategoryList.find(
                    (item) => item?.value === addCourse?.categoryId?._id
                  )
                }
                onChange={handleSelect}
              />
            </FormGroup> */}

            {/* Target Customers */}
            {/* <FormGroup>
              <label className="add_course_form_label my-2 mx-0">
                Target Customers
              </label>

              <Select
                //   defaultValue={[colourOptions[2], colourOptions[3]]}
                isMulti
                name="colors"
                options={customersList}
                className="basic-multi-select"
                classNamePrefix="select"
                value={addCourse?.customersList}
                onChange={handleSelect}

              />

              <div className="d-flex gap-2 my-2">
                <img src={InfoIcon} alt="" />
                <span className="add_course_form_sublabel">
                  Maximum 20 Tags
                </span>
              </div>
            </FormGroup> */}
          </div>

          <div className="d-flex justify-content-end">
            <button
              className="add_course_form_continue_btn"
              type="button"
              onClick={handleContinue}
            >
              Continue
              <img src={DoubleRightIcon} alt="" />
            </button>
          </div>
        </Form>
      </Row>
    </>
  );
};

export default CourseDetails;
