import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { useHistory, useParams } from "react-router";
import {
  createOrganisation,
  editOrganisation,
} from "actions/adminActions/organisationsActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { hideAlert, showAlert } from "actions/templateAction";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "type";
import {
  getOrgUsers,
  getAllOrgUser,
} from "actions/adminActions/organisationsActions";
import Select from "react-select";
import { Checkbox, FormControlLabel } from "@mui/material";
import { object } from "prop-types";
import { updateUserOrganisationPassword } from "actions/adminActions/usersActions";

const statusList = [
  {
    label: "Enabled",
    value: true,
  },
  {
    label: "Disabled",
    value: true,
  },
];
const AddOrgUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [selectOrgData, setSelectOrgData] = useState()

  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);
  const [state, setState] = useState({
    name: '',
    nameErr: null,
    email: '',
    emailErr: null,
    phoneNumber: '',
    phoneNumberErr: null,
    password: '',
    passwordErr: null,
    sendEmail: true,
    userType: 'organisation',
    tabAccess: [],
  });
  const users = useSelector((state) => {
    return state.organisation.orgData;
  });
  useEffect(() => {
    dispatch(getOrgUsers(id, 0, 1000));
    trackPromise(dispatch(getAllOrgUser(0, 2000)), loaderType.GENERAL_REQUEST);
  }, []);
  useEffect(() => {
    let findData = users.find((item) => item._id === id);
    setState({
      name: findData?.name,
      email: findData?.email,
      phoneNumber: findData?.phoneNumber,
      tabAccess: findData?.tabAccess || [],
    });
  }, [users]);

  const validateCustomStylesForm = async () => {
    let newState = state;
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (newState.name === '') {
      newState.nameErr = 'invalid';
    } else {
      newState.nameErr = 'valid';
    }

    if (newState.email === '' || !emailRegex.test(newState.email)) {
      newState.emailErr = 'invalid';
    } else {
      newState.emailErr = 'valid';
      newState.email = newState.email.trim();
    }
    if (newState.phoneNumber === '') {
      newState.phoneNumberErr = 'invalid';
    } else {
      newState.phoneNumberErr = 'valid';
    }

    if (newState.password === '') {
      newState.passwordErr = 'invalid';
    } else {
      newState.passwordErr = 'valid';
    }
    if (
      newState.nameErr === 'invalid' ||
      newState.emailErr === 'invalid' ||
      newState.phoneNumberErr === 'invalid' ||
      newState.passwordErr === 'invalid'
    ) {
      setState({
        ...state,
        ...newState,
      });
    } else {
      if (!id) {
        trackPromise(
          dispatch(createOrganisation(state)),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => {
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      } else {
        updatePassword();
        trackPromise(
          dispatch(editOrganisation(id, state)),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => {
            dispatch(showAlert(true, 'success', res?.data?.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      }
    }
  };
  const updatePassword = () => {
    if (state.password) {
      trackPromise(
        dispatch(updateUserOrganisationPassword(id, state.password)),
        loaderType.GENERAL_REQUEST
      )
        .then((res) => {
          dispatch(showAlert(true, 'success', res.message));
        })
        .catch((err) => {
          dispatch(showAlert(true, 'warning', err.message));
        });
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = state;
    newState[name] = value;
    if (value === '') {
      newState[name + 'Err'] = 'invalid';
    } else {
      newState[name + 'Err'] = 'valid';
    }
    setState({
      ...state,
      ...newState,
    });
  };

  const navigateTo = () =>
    type === 'success' ? history.push('/organisations') : null;
  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo();
    }
  };
  const handleChecked = (e) => {
    const access = state.tabAccess;
    const { name, checked } = e.target;
    if (checked) {
      access.push(name);
    } else {
      const findIndex = access.findIndex((i) => i === name);
      access.splice(findIndex, 1);
    }
    setState({
      ...state,
      tabAccess: access,
    });
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {!id ? 'Add' : 'Edit'} Organisation
                      </h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/organisations')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={() => {
                          validateCustomStylesForm();
                        }}
                      >
                        {id ? 'Edit' : 'Add'} Organisation
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Name
                          </label>
                          <Input
                            value={state.name}
                            className="org_form_input"
                            name="name"
                            placeholder="Name"
                            type="text"
                            autoComplete="off"
                            valid={state.nameErr === 'valid'}
                            invalid={state.nameErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Email
                          </label>
                          <Input
                            value={state.email}
                            name="email"
                            placeholder="Email"
                            type="text"
                            autoComplete="off"
                            valid={state.emailErr === 'valid'}
                            invalid={state.emailErr === 'invalid'}
                            className="org_form_input"
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Phone Number
                          </label>
                          <Input
                            value={state.phoneNumber}
                            name="phoneNumber"
                            placeholder="Phone Number"
                            type="number"
                            autoComplete="off"
                            valid={state.phoneNumberErr === 'valid'}
                            invalid={state.phoneNumberErr === 'invalid'}
                            className="org_form_input"
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Password
                          </label>
                          <Input
                            value={state.password}
                            name="password"
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            valid={state.passwordErr === 'valid'}
                            invalid={state.passwordErr === 'invalid'}
                            className="org_form_input"
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Status
                        </label>

                        <Select
                          value={statusList.find(
                            (item) => item.value === state.isActive
                          )}
                          name="statusList"
                          options={statusList}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: '45px',
                              border: state.categoryIdsErr
                                ? '1px solid red'
                                : '1px solid #d2d6da',
                            }),
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            setState({
                              ...state,
                              status: e.value,
                            });
                          }}
                        />
                      </Col>
                      <Col className="mt-4 mb-2" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Feature Access
                        </label>
                      </Col>

                      <Col className=" mb-2" md="7">
                        <table className="tab-access-tbl">
                          <thead>
                            <tr>
                              <th>Feature</th>
                              <th>Access</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>Users</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isUser')}
                                  name="isUser"
                                  id="isUser"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Quiz</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isQuiz')}
                                  name="isQuiz"
                                  id="isQuiz"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Exams</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isExams')}
                                  name="isExams"
                                  id="isExams"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>My Courses</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isMycourses'
                                  )}
                                  name="isMycourses"
                                  id="isMycourses"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Licenses</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isLicenses'
                                  )}
                                  name="isLicenses"
                                  id="isLicenses"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Announcement</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isAnnouncemnet'
                                  )}
                                  name="isAnnouncemnet"
                                  id="isAnnouncemnet"
                                  onClick={handleChecked}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      {/* <Col className="d-flex align-items-center gap-2" md="7">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.tabAccess.includes("isUser")}
                              name="isUser"
                              id="isUser"
                              onClick={handleChecked}
                            />
                          }
                          label="Users"
                          className="org_form_label "
                        />
                      </Col>
                      <Col className="d-flex align-items-center gap-2" md="7">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.tabAccess.includes("isQuiz")}
                              name="isQuiz"
                              id="isQuiz"
                              onClick={handleChecked}
                            />
                          }
                          label="Quiz"
                          className="org_form_label "
                        />
                      </Col>
                      <Col className="d-flex align-items-center gap-2" md="7">
                        <Checkbox
                          checked={state.tabAccess.includes("isExams")}
                          name="isExams"
                          id="isExams"
                          onClick={handleChecked}
                        />
                        <label
                          form="isExams"
                          className="org_form_label my-2 mx-0"
                        >
                          Exmas
                        </label>
                      </Col>
                      <Col className="d-flex align-items-center gap-2" md="7">
                        <Checkbox
                          checked={state.tabAccess.includes("isMycourses")}
                          name="isMycourses"
                          id="isMycourses"
                          onClick={handleChecked}
                        />
                        <label
                          form="isMycourses"
                          className="org_form_label my-2 mx-0"
                        >
                          My Courses
                        </label>
                      </Col>
                      <Col className="d-flex align-items-center gap-2" md="7">
                        <Checkbox
                          checked={state.tabAccess.includes("isLicenses")}
                          name="isLicenses"
                          id="isLicenses"
                          onClick={handleChecked}
                        />
                        <label
                          form="isLicenses"
                          className="org_form_label my-2 mx-0"
                        >
                          Licenses
                        </label>
                      </Col>
                      <Col className="d-flex align-items-center gap-2" md="7">
                        <Checkbox
                          checked={state.tabAccess.includes("isAnnouncemnet")}
                          name="isAnnouncemnet"
                          id="isAnnouncemnet"
                          onClick={handleChecked}
                        />
                        <label
                          form="isAnnouncemnet"
                          className="org_form_label my-2 mx-0"
                        >
                          Announcement
                        </label>
                      </Col> */}
                    </div>
                    {/* <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        validateCustomStylesForm();
                      }}
                    >
                      Add Organizations
                    </Button> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddOrgUser;
