import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";

import { Badge, Card, CardHeader, Container } from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import PlusIcon from "iconComponents/PlusIcon";
import "./Quiz.css";
import { useState } from "react";
import AddQuestion from "./AddQuestion";
import SmSingleQueIcon from "iconComponents/SmSingleQue";
import SmMultiQueIcon from "iconComponents/SmMultiQue";
import SmDocIcon from "iconComponents/SmDocIcon";
import classNames from "classnames";
import MuiOverride from "components/Common/MuiOverride";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getQuestion } from "actions/adminActions/quizActions";
import { useSelector } from "react-redux";
import api2 from "api2";
import axios from "axios";
import ConfirmationModel from "components/Common/ConfirmationModel";
import { useRef } from "react";

const QuestionsWrapper = () => {
  const [selectCategoryId, setSelectCategoryId] = useState('');

  return (
    <>
      <Questions
        selectCategoryId={selectCategoryId}
        setSelectCategoryId={setSelectCategoryId}
      />
    </>
  );
};
const Questions = ({ selectCategoryId, setSelectCategoryId }) => {
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(100);
  const [editQuestion, setEditQuestion] = useState('');
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });

  const [selectTag, setSelectTag] = useState('');
  const { questionList } = useSelector((state) => state?.quiz);
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "no",
      //   header: "No",
      // },

      {
        header: 'Question',
        accessorKey: 'question',
        Cell: ({ row }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <span className="list-question-name">
                {row?.original?.question}
              </span>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  {row?.original?.answer?.answers?.length === 1 ? (
                    <>
                      <SmMultiQueIcon color={'#070707'} />
                      <span className="list-que-type">Single Choice</span>
                    </>
                  ) : (
                    <>
                      <SmSingleQueIcon color={'#070707'} />
                      <span className="list-que-type">Multiple Choice</span>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <SmDocIcon color={'#070707'} />
                  <span className="list-que-type">Category</span>
                </Box>
              </Box>
            </Box>
          </>
        ),
      },

      {
        accessorKey: 'difficulty',
        header: 'Complexity',
        Cell: ({ row }) => (
          <>
            <Badge
              className={classNames(
                ' complexity-badge ',
                {
                  'complexity-bg-badge-success':
                    row?.original?.difficulty === 'easy',
                },
                {
                  'complexity-bg-badge-warning':
                    row?.original?.difficulty === 'medium',
                },
                {
                  'complexity-bg-badge-danger':
                    row?.original?.difficulty === 'hard',
                }
              )}
            >
              {row?.original?.difficulty}
            </Badge>
          </>
        ),
      },
      {
        header: 'Category',
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '4px' }}>
            {row?.original?.questionCategoryId?.name && (
              <>
                <Category
                  row={row}
                  setSelectCategoryId={setSelectCategoryId}
                  selectCategoryId={selectCategoryId}
                />
              </>
            )}
          </Box>
        ),
      },
      {
        header: 'Tags',
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '4px' }}>
            {row?.original?.tags.map((item, index) => (
              <>
                <Tags
                  setSelectTag={setSelectTag}
                  row={row}
                  item={item}
                  index={index}
                />
              </>
            ))}
            <div>
              {/* {row?.original?.tags?.length > 2 && (
                <span className="list-question-tag">
                  {row?.original?.tags?.length - 2} +
                </span>
              )} */}
            </div>
          </Box>
        ),
      },
    ],
    []
  );
  const handleModel = () => {
    setIsOpen(!isOpen);
    // setEditQuestion("")
    dispatch(getQuestion(0, 1000, selectCategoryId, selectTag));
  };
  useEffect(() => {
    dispatch(getQuestion(skip, limit, selectCategoryId, selectTag));
  }, [dispatch, selectCategoryId, selectTag]);
  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };
  const manageAlert = () => {
    setAlert({ ...alert, isOpenAlert: false });
    dispatch(getQuestion(0, 1000, selectCategoryId, selectTag));
  };
  const confirmDelete = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      await api2
        .delete(`/api/mcq/${selectedQuestion}`, config)
        .then((res, err) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'success',
            message: 'Question deleted successfully!',
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">Questions</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button
                className="pr-btn-with-icon"
                onClick={() => {
                  handleModel();
                  setEditQuestion('');
                }}
              >
                <PlusIcon color="white" />
                Add Question
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={questionList || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <button
                  className="table-action-edit-btn"
                  onClick={(e) => {
                    setEditQuestion(row?.original);
                    handleModel(e);
                  }}
                >
                  <EditIcon color={'#070707'} />
                  Edit
                </button>
                <button
                  type="button"
                  className="table-action-delete-btn"
                  onClick={() => {
                    handleDelete();
                    setSelectedQuestion(row?.original?._id);
                  }}
                >
                  <SmTrashIcon color={'#EF466F'} />
                  Delete
                </button>
              </Box>
            )}
          />
        </MuiOverride>
      </Card>
      <AddQuestion
        isOpen={isOpen}
        editQuestion={editQuestion}
        handleModel={handleModel}
      />
      {alert.status === 'success' && alert.isOpenAlert && (
        // <SweetAlert success title={alert?.message} onConfirm={manageAlert} />
        <ConfirmationModel
          status={alert.status}
          buttonText={'OK'}
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            manageAlert();
            dispatch(getQuestion(0, 1000, selectCategoryId, selectTag));
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
        />
      )}
      {alert.status === 'warning' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            confirmDelete();
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onClose={() => {
            setAlert({
              isOpenAlert: false,
            });
          }}
        />
      )}
    </Container>
  );
};
const Tags = ({ row, setSelectTag, item, index }) => {
  const tagRef = useRef();
  const checkboxRef = useRef();
  const [tags, setTags] = useState();
  return (
    <>
      {/* {index <= 1 && ( */}
      <>
        <span
          ref={tagRef}
          key={index}
          // className="list-question-tag"
          className={classNames({
            "list-question-tag": true,
            "list-question-tag-active": row?.original?.tags.includes(tags),
          })}
          id={item}
          onClick={() => checkboxRef.current.click()}
        >
          {item}
        </span>
        <input
          type="checkbox"
          className="d-none"
          ref={checkboxRef}
          onClick={(e) => {
            const data = tagRef?.current;
            if (e.target.checked) {
              setTags(data?.id);
              setSelectTag(item);
            } else {
              setTags("");
              setSelectTag("");
            }
          }}
        />
      </>
      {/* )} */}
    </>
  );
};
const Category = ({ row, setSelectCategoryId, selectCategoryId }) => {
  const categoryRef = useRef();
  const checkboxRef = useRef();
  return (
    <>
      <input
        type="checkbox"
        className="d-none"
        ref={checkboxRef}
        onClick={(e) => {
          const data = categoryRef?.current;
          if (e.target.checked) {
            data.className = "list-question-tag list-question-tag-active";
            setSelectCategoryId(row?.original?.questionCategoryId?._id);
          } else {
            data.className = "list-question-tag";
            setSelectCategoryId("");
          }
        }}
      />
      <span
        ref={categoryRef}
        className="list-question-tag"
        onClick={() => checkboxRef.current.click()}
      >
        {row?.original?.questionCategoryId?.name}
      </span>
    </>
  );
};
export default QuestionsWrapper;
