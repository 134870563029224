import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";

import { Badge, Card, CardHeader, Container } from "reactstrap";
import EditIcon from "iconComponents/EditIcon";
import SmTrashIcon from "iconComponents/SmTrashIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import PlusIcon from "iconComponents/PlusIcon";
import { useHistory } from "react-router";
import MuiOverride from "components/Common/MuiOverride";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMyCourseExam } from "actions/adminActions/courseAction";
import { useSelector } from "react-redux";
import { useState } from "react";
import api2 from "api2";
import axios from "axios";
import ConfirmationModel from "components/Common/ConfirmationModel";
import classNames from "classnames";

const AllTests = () => {
  const history = useHistory();
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpenAlert: false,
  });
  const dispatch = useDispatch();
  const [selectedLab, setSelectedLab] = useState();
  const totalQuestions = (quizData) => {
    let totalQuestions = 0;
    quizData?.quiz.map((item) => {
      return (totalQuestions += item.mcqs.length);
    });
    return totalQuestions;
  };
  const columns = useMemo(
    () => [
      {
        header: "Test Name",
        Cell: ({ row }) => <>{row?.original?.name}</>,
      },
      {
        header: "Total Questions",
        Cell: ({ row }) => totalQuestions(row?.original),
      },
      {
        header: "Total Labs",
        Cell: ({ row }) => <div>{row?.original?.challengeIds?.length}</div>,
      },
      {
        header: "Status",
        Cell: ({ row }) => (
          <Badge color="" className="badge-dot mr-4">
            <i
              className={classNames(
                { "bg-success": !row?.original.isDraft },
                { "bg-warning": row?.original.isDraft }
              )}
            />
            <span
              className={classNames(
                "status",
                { "text-success": !row?.original.isDraft },
                { "text-warning": row?.original.isDraft }
              )}
            >
              {row?.original.isDraft ? "Draft" : "Publish"}
            </span>
          </Badge>
        ),
      },
    ],
    []
  );
  const handleTest = () => {
    history.push("/exam/create-new");
  };

  const myExamList = useSelector((state) => state?.courses?.myExamList);

  useEffect(() => {
    dispatch(getMyCourseExam(0, 1000, "exam"));
  }, []);
  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: "warning",
      message: "Are you sure you want to delete?",
    });
  };
  const confirmDelete = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      await api2
        .delete(`/api/course/exam/${selectedLab}`, config)
        .then((res, err) => {
          setAlert({
            ...alert,
            isOpenAlert: true,
            status: 'success',
            message: 'Exam deleted successfully!',
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  const manageAlert = () => {
    setAlert({ ...alert, isOpenAlert: false });
    dispatch(getMyCourseExam(0, 1000, "exam"));
  };
  return (
    <Container fluid>
      <Card className="">
        <CardHeader>
          <div className="add_course_page_header ">
            <div>
              <h5 className="org_page_title">All Tests</h5>
            </div>

            <div className="d-flex gap-1 align-items-center">
              <button className="pr-btn-with-icon" onClick={handleTest}>
                <PlusIcon color="white" />
                Add Test
              </button>
            </div>
          </div>
        </CardHeader>

        <MuiOverride>
          <MaterialReactTable
            columns={columns}
            data={myExamList || []}
            enableRowActions
            enableRowNumbers
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <button
                  className="table-action-edit-btn"
                  onClick={() => {
                    history.push(`/exam/edit/${row?.original?._id}`);
                  }}
                >
                  <EditIcon color={'#070707'} />
                  Edit
                </button>
                <button
                  className="table-action-delete-btn"
                  onClick={() => {
                    handleDelete();
                    setSelectedLab(row?.original?._id);
                  }}
                >
                  <SmTrashIcon color={'#EF466F'} />
                  Delete
                </button>
              </Box>
            )}
          />
        </MuiOverride>
      </Card>
      {alert.status === 'success' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          buttonText={'OK'}
          confirmation={() => {
            manageAlert();
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
        />
      )}
      {alert.status === 'warning' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            confirmDelete();
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onClose={() => {
            setAlert({
              isOpenAlert: false,
            });
          }}
        />
      )}
    </Container>
  );
};

export default AllTests;
